import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        width: 60,
        background: '#F26522',
        padding: 0.5,
        height: 2,
      },
}))

export default useStyles