import { ApiException } from "@clients/api-exception";
import { Messages } from "@shared/utils/constants/messages";
import { InsuranceTypeEnum } from "@enums/insurance-type.enum";
import HttpStatusCode from "@shared/utils/enums/http-status-code.enum";
import { ProductConfig } from "@models/product-config/product-config.model";
import arraysEqual from "@shared/utils/functions/check-equal-arrays.function";
import { ProductConfigClient } from "@clients/product-config/product-config.client";
import { PageSaveProductConfigMessages } from "@pages/save-product-config/dtos/messages.dto";

const validate = (productConfig: ProductConfig): string => {

  if (!productConfig.productId)
    return PageSaveProductConfigMessages.PRODUCT_FIELD_IS_REQUIRED_MESSAGE;

  if (productConfig.delayNotification?.delayNotificationDays < 0)
    return PageSaveProductConfigMessages.DELAY_NOTIFICATION_MUST_BE_GREATER_OR_EQUAL_TO_ZERO_MESSAGE;

  if (!productConfig.iofConfigs?.length)
    return PageSaveProductConfigMessages.IOF_CONFIG_IS_REQUIRED_MESSAGE;

  const defaultPaymentConditions = productConfig.paymentConditions.filter(x => x.isDefaultPaymentType);
  if (!defaultPaymentConditions?.length)
    return PageSaveProductConfigMessages.PAYMENT_CONDITION_IS_REQUIRED_MESSAGE;

  const groupedPaymentConditionsByInsuranceType = groupBy(productConfig.paymentConditions, (item) => item.insuranceType);
  for(const [insuranceType, selectedPaymentCondition] of groupedPaymentConditionsByInsuranceType.entries()){
    if(!selectedPaymentCondition.some(i => i.enableOnPortal))
      return PageSaveProductConfigMessages.ENABLE_ON_PORTAL_IS_REQUIRED_MESSAGE;
  }


    const paymentConditionsInsuranceTypes = [...new Set(productConfig.paymentConditions.map(x => x.insuranceType))];
  const paymentConditionsDefaultPaymentTypeInsuranceTypes = [...new Set(productConfig.paymentConditions.filter(x => x.isDefaultPaymentType).map(x => x.insuranceType))];

  const hasEqualsDefaultPaymentTypeByInsuranceType = arraysEqual(paymentConditionsInsuranceTypes, paymentConditionsDefaultPaymentTypeInsuranceTypes);

  if(!hasEqualsDefaultPaymentTypeByInsuranceType)
  return PageSaveProductConfigMessages.DIFFERENTS_DEFAULT_PAYMENT_CONDITION_BY_INSURANCE_TYPE_MESSAGE;

  const iofConfigsInsuranceTypes = [...new Set(productConfig.iofConfigs.map(x => x.insuranceType))];

  const hasEqualInsurancesTypes = arraysEqual(iofConfigsInsuranceTypes, paymentConditionsDefaultPaymentTypeInsuranceTypes);

  if (!hasEqualInsurancesTypes)
    return PageSaveProductConfigMessages.DIFFERENTS_INSURANCE_TYPES_BETWEEN_IOF_CONFIG_AND_PAYMENT_CONDITION_MESSAGE;

  return null;
}

const getProductConfig = async (productId: string): Promise<ProductConfig> => {

  try {
    const response = await ProductConfigClient.getProductConfig(productId);
    return response as ProductConfig;
  } catch (err: unknown) {

    if (!(err instanceof ApiException))
      throw new ApiException(Messages.UNEXPECTED_ERROR);

    const apiException = (err as ApiException);
    if (apiException.status === HttpStatusCode.NOT_FOUND)
      throw new ApiException(Messages.PRODUCT_CONFIG_WAS_NOT_FOUND, apiException.status);

    throw new ApiException(Messages.UNEXPECTED_ERROR, apiException.status);
  }
}

const saveProductConfig = async (productConfig: ProductConfig, username: string): Promise<void> => {

  try {
    await ProductConfigClient.saveProductConfig(productConfig, username);
  } catch (err: unknown) {
    if (!(err instanceof ApiException))
      throw new ApiException(Messages.UNEXPECTED_ERROR);

    const apiException = (err as ApiException);
    if (apiException.status === HttpStatusCode.NOT_FOUND)
      throw new ApiException(Messages.PRODUCT_CONFIG_WAS_NOT_FOUND, apiException.status);

    if (apiException.status === HttpStatusCode.BAD_REQUEST)
      throw new ApiException(apiException.message, apiException.status);

    throw new ApiException(Messages.UNEXPECTED_ERROR, apiException.status);
  }
}

const groupBy: <T, K>(array: T[], keyGetter: (item: T) => K) => Map<K, T[]> = <T, K>(array: T[], keyGetter: (item: T) => K): Map<K, T[]> => {
  const map = new Map<K, T[]>();

  array.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
};

export const SaveProductConfigPageServices = {
  validate,
  getProductConfig,
  saveProductConfig
}
