import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@material-ui/core'

export const Pagination = ({ totalResults, changePaginator }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (event, value) => {
    setPage(value)
    changePaginator(value)
  };

  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <TablePagination
        component="div"
        count={totalResults}
        page={page}
        onPageChange={handleChangePage}
        showFirstButton={true}
        showLastButton={true}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </Box >
  )
}