import { EnumType } from "typescript";

export default function getEnumDescriptions(enumTypeLabel: Map<string, string>, enumType: EnumType): any[] {
    let enumTypes = [];

    for (let element in enumType) {
        
        enumTypes.push({
            text: enumTypeLabel.get(enumType[element]),
            value: enumType[element]
        })
    }

    return enumTypes;
}