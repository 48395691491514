export enum BankEnum {
    Itau = "Itau",
    Bradesco = "Bradesco",
    CaixaEconomicaFederal = "CaixaEconomicaFederal",
    Santander = "Santander"
}
export const BankNumberLabel = new Map<string, string>([
    [BankEnum.Itau, '341'],
    [BankEnum.Bradesco, '237'],
    [BankEnum.CaixaEconomicaFederal, '104'],
    [BankEnum.Santander, '033']
])

export const BankNameLabel = new Map<string, string>([
    [BankEnum.Itau, 'Itaú'],
    [BankEnum.Bradesco, 'Bradesco'],
    [BankEnum.CaixaEconomicaFederal, 'Caixa Econômica Federal'],
    [BankEnum.Santander, 'Santander']
])

export const BankEnumLabel = new Map<string, string>([
    [BankEnum.Itau, 'Itau'],
    [BankEnum.Bradesco, 'Bradesco'],
    [BankEnum.CaixaEconomicaFederal, 'CaixaEconomicaFederal'],
    [BankEnum.Santander, 'Santander']
])

export const BankNumberEnumLabel = new Map<string, string>([
    ['341', BankEnum.Itau,],
    ['237', BankEnum.Bradesco,],
    ['104', BankEnum.CaixaEconomicaFederal],
    ['033', BankEnum.Santander]
])