import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
    menu: {
      '& .MuiPopover-paper': {
        borderRadius: '14px',
      },
    },
    btn: {
      background: 'transparent',
      color: '#000',
      height: '50px',
      border: '1px solid #f26522',
      borderRadius: '11px',
      '& .MuiIcon-root': {
        color: theme.palette.secondary.contrastText,
      },
    },
    name: {
      fontWeight: 600,
      fontSize: '1.05rem',
    },
    buttonsMenu: {
      border: '1px solid transparent',
      borderLeft: 'none',
      borderRight: 'none',
      width: '100%',
      borderRadius: '0px',
      fontWeight: 600,
    },
    buttonFeed: {
      borderBottom: 'none',
      display: 'initial',
    },
    buttonPwd: {
      borderBottom: 'none',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    iconForm: {
      color: 'gray',
      fontSize: '22px',
    },
    bold: {
      fontWeight: 600,
    },
    labelInfo: {
      color: '#9c9898',
      fontSize: '12px',
    },
    logoutMenuIcon:{
      color: '#f26522'
    }
  }))