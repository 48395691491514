import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'

import reducer from '@storages/ducks'

const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});
const persist = persistStore(store);

export { store, persist }
