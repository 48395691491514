import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import userManager from '@security/user-manager'
import jwtDecoder from 'jwt-decode'
import IUserToken from './user-token.interface'
import { useSecurityAction } from '@storages/ducks/security'
import { UserSecurity } from '@models/user-security/user-security.model'

const useSecurity = () => {
  const { cleanCredential } = useSecurityAction()

  const { credential } = useSelector(({ security }: { security: any }) => ({
    credential: security.credential
  }))

  const getName = useCallback((): string => {
    const { profile } = credential
    return profile?.name || ''
  }, [credential])

  const getUsername = useCallback((): string => {
    const { profile } = credential
    return profile?.preferred_username || ''
  }, [credential])

  const getUserProfile = useCallback((): UserSecurity => {
    const { profile } = credential
    return profile as UserSecurity ?? null
  }, [credential])

  const getPersonId = useCallback((): string => {
    const { profile = {} } = credential || {}
    return profile.person_id || ''
  }, [credential])

  const getAccessToken = useCallback((): string => credential?.access_token, [credential])

  const getIdToken = useCallback((): string => credential?.id_token, [credential])

  const getUserToken = useCallback((): IUserToken => {
    const userToken = jwtDecoder(credential?.access_token)
    return userToken as IUserToken
  }, [credential]
  )

  const isLoggedIn = useCallback((): boolean => {
    let loggedIn = false

    if (credential) {
      const currentAt = Math.round(Date.now() / 1000)
      loggedIn = credential.expires_at > currentAt
    }
    return loggedIn
  }, [credential])

  const signin = useCallback(() => userManager.signinRedirect(), [])

  const signout = useCallback((data) => {
    cleanCredential()
    userManager.signoutRedirect({ state: data || '' })
    userManager.signoutRedirectCallback("/");
  }, [cleanCredential])

  return {
    getName,
    getIdToken,
    getUsername,
    getPersonId,
    getAccessToken,
    isLoggedIn,
    credential,
    userManager,
    signin,
    signout,
    getUserToken,
    getUserProfile
  }
}

export default useSecurity
