import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSecurity from "@security/user-security";
import { PageMenuPrincipalService } from "@pages/menu-principal/services";
import {
  Button,
  ButtonEnum,
  ColorEnum,
  Icon,
  SizeEnum,
  Typography,
  TypographyVariantEnum,
  useActiveHeader,
  useSubHeader,
} from "@pottencial-tangerine/ui";

export const MenuPrincipal = () => {
  const { credential, getName } = useSecurity();
  const navigate = useNavigate();
  useActiveHeader("home");
  useSubHeader({
    title: getName(),
    welcome: true,
  });

  const verifyUserAccess = async (email: string) => {
    await PageMenuPrincipalService.verifyUserAccess(email);
  };

  useEffect(() => {
    verifyUserAccess(credential.profile.email);
  }, []);

  return (
    <>
      <Box className="App">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              marginTop: "60px",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Icon
              url="tangerine/logos/pottencial-symbol-bg"
              height="128px"
              width="128px"
              color={ColorEnum.neutral100}
            />

            <Typography
              variant={TypographyVariantEnum.h4}
              color={ColorEnum.neutral600}
            >
              Gestão Financeira do Núcleo de Pagamentos
            </Typography>

            <Typography
              variant={TypographyVariantEnum.h5}
              color={ColorEnum.neutral400}
            >
              Oferece uma plataforma abrangente que fornece informações
              essenciais sobre o IOF, os Canais de Venda e as Formas de
              Pagamento relacionadas ao seu produto. Com ele, você pode
              facilmente acessar dados cruciais para otimizar e controlar as
              finanças do seu negócio, simplificando assim a gestão de
              transações e garantindo uma visão clara de todas as operações
              financeiras.
            </Typography>

            <Button
              onClick={() => navigate("/products")}
              size={SizeEnum.small}
              variant={ButtonEnum.contained}
              iconRight={
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.7928 22.7071C21.1834 23.0976 21.8165 23.0976 22.2071 22.7071L28.2071 16.7071C28.5976 16.3166 28.5976 15.6834 28.2071 15.2929L22.2071 9.29289C21.8165 8.90237 21.1834 8.90237 20.7928 9.29289C20.4023 9.68342 20.4023 10.3166 20.7928 10.7071L25.0857 15H5.5C4.94772 15 4.5 15.4477 4.5 16C4.5 16.5523 4.94772 17 5.5 17H25.0857L20.7928 21.2929C20.4023 21.6834 20.4023 22.3166 20.7928 22.7071Z"
                    fill="white"
                  />
                </svg>
              }
            >
              Produtos
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};
