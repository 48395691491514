import IofConfig from "@models/product-config/iof-config.model";
import ChannelConfig from "@models/product-config/channel-config.model";
import PaymentCondition from "@models/product-config/payment-condition/payment-condition.model";
import DelayNotification from "@models/product-config/delay-notification.model";
import ExtendDueDateConfig from "@models/product-config/extend-due-date-config.model";
import { ElapsedTableStrategyCalculationType } from "@enums/elapsed-table-strategy-calculation.enum";

export class ProductConfig {

  productId: string;

  name: string;
  
  branch: string;

  allowCancellationDebt: boolean;
  
  allowAutomaticCancel: boolean;

  iofConfigs: Array<IofConfig>;

  channelConfigs: Array<ChannelConfig>;

  paymentConditions: Array<PaymentCondition>;

  extendDueDateConfigs: Array<ExtendDueDateConfig>;

  delayNotification: DelayNotification;

  elapsedTableStrategyCalculation: ElapsedTableStrategyCalculationType;

  constructor() {
    this.allowCancellationDebt = false;
    this.allowAutomaticCancel = false;
    this.iofConfigs = new Array<IofConfig>();
    this.delayNotification = new DelayNotification();
    this.channelConfigs = new Array<ChannelConfig>();
    this.paymentConditions = new Array<PaymentCondition>();
    this.extendDueDateConfigs = new Array<ExtendDueDateConfig>();
  }
}