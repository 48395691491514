import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core'

import { theme } from '@theme'
import { DEFAULT_THEME } from '@layouts/index'

const AppTheme = ({ children }) => {
  const theme2 = theme(DEFAULT_THEME)

  return (
    <ThemeProvider theme={theme2}>
      {children}
    </ThemeProvider>
  )
}

AppTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
}

export default AppTheme
