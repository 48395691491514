import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import produce from 'immer'

const INITIAL_STATE = {
  credential: null,
}

export const Types = {
  CREDENTIAL: 'security/CREDENTIAL',
}

const reducer = (state = INITIAL_STATE, action) => {
  if (action.type === Types.CREDENTIAL) {
    const { credential } = action.payload

    return produce(state, (draft) => {
      draft.credential = credential
    })
  }

  return state
}

export const useSecurityAction = () => { 
  const dispatch = useDispatch()

  const setCredential = useCallback((credential) => dispatch({   
    type: Types.CREDENTIAL,
    payload: { credential },
  }), [dispatch])

  const cleanCredential = useCallback(() => dispatch({
    type: Types.CREDENTIAL,
    payload: { credential: null },
  }), [dispatch])

  return {
    setCredential,
    cleanCredential,
  }
}

export default persistReducer({
  key: 'security',
  storage,
}, reducer)
