export enum PaymentTypeEnum {
    Boleto = "0",
    Invoice = "1",
    CreditCardCheckout = "2",
    Ted = "3",
    Debit = "5",
    CreditCardRecurrence = "7"
}
export const PaymentTypeEnumLabel = new Map<string, string>([
    [PaymentTypeEnum.Boleto, 'Boleto'],
    [PaymentTypeEnum.Invoice, 'Fatura'],
    [PaymentTypeEnum.CreditCardCheckout, 'Cartão de Crédito'],
    [PaymentTypeEnum.Ted, 'Ted'],
    [PaymentTypeEnum.Debit, 'Débito'],
    [PaymentTypeEnum.CreditCardRecurrence, 'Cartão de Crédito Recorrente']
])
