import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Delete, Edit } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { DEFAULT_THEME } from "@layouts/index";
import { IDynamicTableProps } from "@components/dynamic-table/intefaces/dynamic.table.interface";
import { useEffect } from "react";

export const DynamicTable = (props: IDynamicTableProps) => {


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: DEFAULT_THEME.primary,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.head}: first-of-type`]: {
            borderRadius: '1em 0 0 0',
        },
        [`&.${tableCellClasses.head}: last-of-type`]: {
            borderRadius: '0 1em 0 0',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const createHeaders = () => {
        const columns = [];
        props.data.columns.forEach(element => {
            if (element.visible) {
                const header = <StyledTableCell align="center">{element.name}</StyledTableCell>
                columns.push(header)
            }
        });
        if (columns.length > 0)
            columns.push(<StyledTableCell align="center">Ações</StyledTableCell>)
        return columns;
    }

    const createRows = () => {
        const rows = [];
        props.data.rows.forEach(element => {
            const properties = Object.keys(element);

            properties.map(property => {
                <TableCell align="center">{element[property]}</TableCell>
            });

            const row = <TableRow key={element.id}>
                {properties.map((property, index) => {
                    if ((props.data.columns[index]) && (props.data.columns[index].visible)) {
                        return (<TableCell align="center">{element[property]}</TableCell>)
                    }
                })}

                <TableCell align="center">
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <div style={{ margin: '2%' }}>
                            <Button
                                size="small"
                                startIcon={<Edit />}
                                color="primary"
                                variant="outlined"
                                onClick={() => props.actionEditClick(element)}
                            >
                                Editar
                            </Button></div>
                        <div style={{ margin: '2%' }}>
                            <Button
                                size="small"
                                startIcon={<Delete />}
                                color="secondary"
                                variant="outlined"
                                onClick={() => props.actionDeleteClick(element)}
                            >
                                Excluir
                            </Button>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
            rows.push(row)
        });
        return rows;
    }

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Table style={{ borderRadius: '1em 1em 0 0' }}>
                <TableHead>
                    <TableRow>
                        {createHeaders()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {createRows()}
                </TableBody>
            </Table>
        </Box>
    )
}
