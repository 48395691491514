import PaymentCondition from "@models/product-config/payment-condition/payment-condition.model";
import { IRowPaymentConditionConfig } from "@components/product-config/product-payment-condition/interfaces/table.row.interface";
import { DocumentTypeEnum, InsuranceTypeEnum, InsuranceTypeLabel, IsDefaultPaymentTypeLabel, PaymentProcessingTimeType, PaymentTypeEnum, PaymentTypeEnumLabel } from "@enums/index";
import { Wallet } from "@models/product-config/payment-condition/boleto-payment-condition/wallet.model";
import {EnableOnPortalType, EnableOnPortalTypeLabel} from "@enums/enable-on-portal.enum";

export default class PaymentConditionConfigDto implements IRowPaymentConditionConfig {
    id: string;
    delayPercentage: number;
    maxInstallments: number;
    insuranceType: InsuranceTypeEnum;
    insuranceTypeDescription: string;
    paymentType: PaymentTypeEnum;
    paymentTypeDescription: string;
    interestPercentage: number;
    isDefaultPaymentType: boolean;
    isDefaultPaymentTypeDescription: string;
    limitDaysAfterExpiration: number;
    enableOnPortal: boolean;
    enableOnPortalDescription: string;

    // BOLETO BANK AGREEMENT
    enable: boolean;
    bankName: string;
    bankNumber: string;
    providerName: string;
    companyName: string;
    companyDocument: string;
    walletCode:string;
    walletNumber: string;
    agencyNumber: string;
    documentType: DocumentTypeEnum;
    agreementNumber: string;
    accountNumber: string;
    accountNumberDigit: string;
    paymentProcessingTimeType: PaymentProcessingTimeType;
    wallets: Array<Wallet>;

    // CREDIT CARD
    urlExpirationMinutes: string;
    paymentExpirationMinutes: string;

    constructor(id: string, paymentCondition: PaymentCondition) {
        this.id = id;
        this.maxInstallments = paymentCondition.maxInstallments;
        this.delayPercentage = paymentCondition.delayPercentage;
        this.interestPercentage = paymentCondition.interestPercentage;
        this.isDefaultPaymentType = paymentCondition.isDefaultPaymentType;
        this.enableOnPortal = paymentCondition.enableOnPortal;
        this.limitDaysAfterExpiration = paymentCondition.limitDaysAfterExpiration;
        this.paymentType = PaymentTypeEnum[paymentCondition.paymentType];
        this.insuranceType = InsuranceTypeEnum[paymentCondition.insuranceType];
        this.paymentTypeDescription = PaymentTypeEnumLabel.get(PaymentTypeEnum[paymentCondition.paymentType]);
        this.insuranceTypeDescription = InsuranceTypeLabel.get(InsuranceTypeEnum[paymentCondition.insuranceType]);
        this.isDefaultPaymentTypeDescription = IsDefaultPaymentTypeLabel.get(Number(paymentCondition.isDefaultPaymentType));
        this.enableOnPortalDescription = EnableOnPortalTypeLabel.get(Number(paymentCondition.enableOnPortal))
        this.urlExpirationMinutes = paymentCondition.urlExpirationMinutes;
        this.paymentExpirationMinutes = paymentCondition.paymentExpirationMinutes;

        const enableBankAgreement = paymentCondition.bankAgreements?.find(x => x.enable);

        this.enable = enableBankAgreement?.enable;

        this.bankNumber = enableBankAgreement?.bankNumber;
        this.wallets = enableBankAgreement?.wallets;
        this.companyName = enableBankAgreement?.companyName;
        this.providerName = enableBankAgreement?.providerName;
        this.companyDocument = enableBankAgreement?.companyDocument;

        this.bankName = enableBankAgreement?.bankName ?? null;
    }
}
