import axios from "axios";
import { ApiException } from "@clients/api-exception";
import GetProductResponse from "@clients/product/dtos/get-product.response.dto";

const getProducts = async (): Promise<GetProductResponse | ApiException> => {
  try {
    const response = await axios.get(`/api/v1/products`, {});
    return response.data;
  } catch (err) {
    return new ApiException(err.message || 'Erro ao obter dados', err.status);
  }
}

export const ProductClient = {
  getProducts,
}




