import { ChannelType } from 'enums';
import { ChannelTypeNumber } from "@enums/channel-type.enum";
import ChannelConfig from "@models/product-config/channel-config.model";
import { IRowTableChannelConfig } from "@components/product-config/channel-config/interfaces/table.row.interface";

export default class ChannelConfigDto implements IRowTableChannelConfig {
    id: string;

    channelType: ChannelType;

    creditCardUrlCallback: string;

    creditCardUrlCallbackAfterSale: string;

    constructor(id: string, channelConfig: ChannelConfig = null) {
        this.id = id;
        this.creditCardUrlCallback = channelConfig?.creditCardUrlCallback;
        this.creditCardUrlCallbackAfterSale = channelConfig?.creditCardUrlCallbackAfterSale;
        this.channelType = channelConfig ? ChannelTypeNumber.get(channelConfig.type.toString()) : null;
    }
}