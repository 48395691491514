import { merge } from 'lodash'
import { createTheme, responsiveFontSizes } from '@material-ui/core'

import MuiBaseConfig from '@theme/mui-base-config.theme'
import { DEFAULT_THEME } from '@layouts/index'

export const theme = (color = DEFAULT_THEME) => {
  const configs = {
    name: color.id,
    palette: {
      primary: {
        main: '#F26522',
      },
      secondary: {
        main: '#d10000',
        contrastText: '#8B7676',
      },
      text: {
        primary: '#3C3C3C',
      },
      error: {
        main: '#C3284C',
      },
    },
  }

  const options = merge({}, MuiBaseConfig, configs)
  const theme2 = createTheme(options, [{ size: 10 }])
  return responsiveFontSizes(theme2)
}

export default createTheme
