import { Box, Button, Card, Divider, Grid, InputLabel, NativeSelect, TextField, Typography } from "@material-ui/core"
import useStyles from "@components/product-config/product-payment-condition/style";
import { AddCircle } from "@mui/icons-material";
import { PaymentTypeEnum, PaymentTypeEnumLabel } from "@enums/payment-type.enum";
import { InsuranceTypeEnum, InsuranceTypeLabel } from "@components/product-config/product-payment-condition/dtos/insurance-type.enum";
import SwitchButton from "@components/switch-button/switch-button";
import React, { useEffect, useState } from "react";
import { DynamicTable } from "@components/dynamic-table/dynamic-table";
import { PaymentConditionTableStructure } from "@components/product-config/product-payment-condition/dtos/table-structure.dto";
import PaymentConditionConfigDto from "@components/product-config/product-payment-condition/dtos/payment-condition-config.dto";
import { useForm } from "react-hook-form";
import { BoletoFieldsComponent } from "@components/product-config/product-payment-condition/components/boleto-fields.component";
import { CreditCardFieldsComponent } from "@components/product-config/product-payment-condition/components/credit-card-fields.component";
import MessageBox from "@components/message-box";
import { IRowPaymentConditionConfig } from "@components/product-config/product-payment-condition/interfaces/table.row.interface";
import IProductPaymentConditionComponentProps from "@components/product-config/product-payment-condition/interfaces/product-payment-condition.component.props";
import WalletDto from "./dtos/wallet.dto";
import PaymentCondition from "@models/product-config/payment-condition/payment-condition.model";
import { BoletoBankAgreement } from "@models/product-config/payment-condition/boleto-payment-condition/boleto-bank-agreement.model";

const emptyTableState = new PaymentConditionTableStructure(new Array<PaymentConditionConfigDto>(), 0);

const fieldDefaultValues = {
    ID: null,
    PAYMENT_TYPE: PaymentTypeEnum.Boleto,
    INSURANCE_TYPE: InsuranceTypeEnum.Insurance,
    MAX_INSTALLMENTS: '',
    DELAY_PERCENTAGE: '',
    INTEREST_PERCENTAGE: '',
    IS_DEFAULT_PAYMENT_TYPE: false,
    ENABLE_ON_PORTAL: false,
    WALLETS: new Array<WalletDto>()
}

const fieldNames = {
    ID: 'id',
    PAYMENT_TYPE: 'paymentType',
    INSURANCE_TYPE: 'insuranceType',
    DEFAULT_PAYMENT_TYPE: 'defaultPaymentType',
    MAX_INSTALLMENTS: 'maxInstallments',
    DELAY_PERCENTAGE: 'delayPercentage',
    INTEREST_PERCENTAGE: 'interestPercentage',
    WALLETS: 'wallets'
}

export const ProductPaymentConditionComponent: React.FC<IProductPaymentConditionComponentProps> = ({ productConfig }) => {
    const [tableDataState, setTableDataState] = useState(emptyTableState)
    const [isDefaultPaymentType, setIsDefaultPaymentType] = useState(false)
    const [enableOnPortal, setEnableOnPortal] = useState(false)
    const [paymentConditionConfigToDelete, setPaymentConditionConfigToDelete] = useState(null as IRowPaymentConditionConfig)
    const [openDeleteConfirmationState, setOpenDeleteConfirmationState] = useState(false)
    const [paymentConditionDuplicatedMessageOpenedState, setPaymentConditionDuplicatedMessageOpenedState] = useState(false)
    const [currentPaymentType, setCurrentPaymentType] = useState(PaymentTypeEnum.Boleto)
    const { handleSubmit, register, setValue, formState: { errors } } = useForm();
    const classes = useStyles()
    const boletoComponentClearFields = React.useRef(null)
    const boletoComponentEditFields = React.useRef(null)
    const creditCardComponentClearFields = React.useRef(null)
    const creditCardComponentEditFields = React.useRef(null)
    const createBankAgreement = React.useRef(null);
    const [currentPaymentIdState, setCurrentPaymentIdStateState] = useState(null)

    const addNewPaymentCondition = (data: PaymentConditionConfigDto) => {
        data.isDefaultPaymentType = data.isDefaultPaymentType ?? isDefaultPaymentType;
        data.enableOnPortal = data.enableOnPortal ?? enableOnPortal;

        if (data.id == null && currentPaymentIdState != null) {
            data.id = currentPaymentIdState;
        }

        if (paymentConditionAlreadyExists(tableDataState.rows, data)) {
            setPaymentConditionDuplicatedMessageOpenedState(true);
            return;
        }

        if (!data.id)
            data.id = (tableDataState.rows.length + 1).toString();

        const paymentConditionConfigDtos = tableDataState.rows as unknown as PaymentConditionConfigDto[];

        let editedPaymentCondition = paymentConditionConfigDtos.find(i => i.id === data.id);

        if (data.isDefaultPaymentType) {
            setOtherPaymentTypeIsDefaultPaymentTypeAsFalse(data);
        }

        if (!editedPaymentCondition) {
            paymentConditionConfigDtos.push(data);
            editedPaymentCondition = data;
        } else {
            Object.assign(editedPaymentCondition, data);
        }

        setPaymentConditionConfigOnProductConfig(editedPaymentCondition);

        const newTableDataState = new PaymentConditionTableStructure(paymentConditionConfigDtos, paymentConditionConfigDtos.length);

        setTableDataState(newTableDataState);

        clearPaymentConditionFields();
    }

    const setOtherPaymentTypeIsDefaultPaymentTypeAsFalse = (data: PaymentConditionConfigDto) => {

        var rowsWithInsuranceType = tableDataState.rows.filter(r => Number(r.insuranceType) === Number(data.insuranceType));

        rowsWithInsuranceType.forEach((paymentCondition) => {
            paymentCondition.isDefaultPaymentType = (paymentCondition.paymentType === data.paymentType);
        })
    }

    const setPaymentConditionConfigOnProductConfig = (editedPaymentCondition: PaymentConditionConfigDto) => {

        let paymentConditionToEdit = productConfig.paymentConditions.find(x => x.id === editedPaymentCondition.id);

        let isNewPayment = false;

        if (!paymentConditionToEdit) {
            isNewPayment = true;
            paymentConditionToEdit = new PaymentCondition();
        }

        paymentConditionToEdit.id = editedPaymentCondition.id;
        paymentConditionToEdit.paymentType = editedPaymentCondition.paymentType;
        paymentConditionToEdit.maxInstallments = editedPaymentCondition.maxInstallments;
        paymentConditionToEdit.delayPercentage = editedPaymentCondition.delayPercentage;
        paymentConditionToEdit.interestPercentage = editedPaymentCondition.interestPercentage;
        paymentConditionToEdit.isDefaultPaymentType = editedPaymentCondition.isDefaultPaymentType;
        paymentConditionToEdit.enableOnPortal = editedPaymentCondition.enableOnPortal;
        paymentConditionToEdit.insuranceType = editedPaymentCondition.insuranceType;
        paymentConditionToEdit.urlExpirationMinutes = editedPaymentCondition.urlExpirationMinutes;
        paymentConditionToEdit.paymentExpirationMinutes = editedPaymentCondition.paymentExpirationMinutes;
        paymentConditionToEdit.limitDaysAfterExpiration = editedPaymentCondition.limitDaysAfterExpiration;

        
        if (paymentConditionToEdit.paymentType === PaymentTypeEnum.Boleto) {
            let enableBankAgreement = paymentConditionToEdit.bankAgreements.find(x => x.enable);

            if(isNewPayment){
                enableBankAgreement = new BoletoBankAgreement();
                enableBankAgreement.enable = true;
            }

            enableBankAgreement.bankName = editedPaymentCondition.bankName;
            enableBankAgreement.bankNumber = editedPaymentCondition.bankNumber;
            enableBankAgreement.companyName = editedPaymentCondition.companyName;
            enableBankAgreement.providerName = editedPaymentCondition.providerName;
            enableBankAgreement.companyDocument = editedPaymentCondition.companyDocument;
            editedPaymentCondition.wallets = enableBankAgreement.wallets;

            if(isNewPayment)
                paymentConditionToEdit.bankAgreements.push(enableBankAgreement);
        }
        if (isNewPayment)
            productConfig.paymentConditions.push(paymentConditionToEdit);
    }

    const clearPaymentConditionFields = () => {
        setValue(fieldNames.ID, fieldDefaultValues.ID);
        setValue(fieldNames.PAYMENT_TYPE, fieldDefaultValues.PAYMENT_TYPE);
        setValue(fieldNames.INSURANCE_TYPE, fieldDefaultValues.INSURANCE_TYPE);
        setValue(fieldNames.MAX_INSTALLMENTS, fieldDefaultValues.MAX_INSTALLMENTS);
        setValue(fieldNames.DELAY_PERCENTAGE, fieldDefaultValues.DELAY_PERCENTAGE);
        setValue(fieldNames.INTEREST_PERCENTAGE, fieldDefaultValues.INTEREST_PERCENTAGE);
        setValue(fieldNames.WALLETS, fieldDefaultValues.WALLETS);

        if (currentPaymentType == PaymentTypeEnum.Boleto && boletoComponentClearFields.current) {
            boletoComponentClearFields.current(true);
        }

        if (creditCardComponentClearFields.current) {
            creditCardComponentClearFields.current();
        }

        setCurrentPaymentType(fieldDefaultValues.PAYMENT_TYPE);
        setIsDefaultPaymentType(fieldDefaultValues.IS_DEFAULT_PAYMENT_TYPE);
        setEnableOnPortal(fieldDefaultValues.ENABLE_ON_PORTAL)
    }
    const paymentConditionAlreadyExists = (list: Array<IRowPaymentConditionConfig>, object: IRowPaymentConditionConfig) => {
        let listItems = list.filter(o => Number(o.paymentType) === Number(object.paymentType) &&
            Number(o.insuranceType) === Number(object.insuranceType));

        if (listItems.length === 0) {
            return false;
        }

        if ((listItems.length === 1) && (listItems[0].id === object.id)) {
            return false;
        }

        return true;
    }

    const actionDelete = (selectedItem: IRowPaymentConditionConfig) => {
        setPaymentConditionConfigToDelete(selectedItem);
        setOpenDeleteConfirmationState(true);
    }

    const actionSelectPaymentConditionConfig = (selectedItem: IRowPaymentConditionConfig) => {
        setValue(fieldNames.ID, selectedItem.id);
        setCurrentPaymentIdStateState(selectedItem.id);
        setCurrentPaymentType(selectedItem.paymentType);
        setIsDefaultPaymentType(Boolean(selectedItem.isDefaultPaymentType));
        setEnableOnPortal(Boolean(selectedItem.enableOnPortal));
        setValue(fieldNames.PAYMENT_TYPE, selectedItem.paymentType);
        setValue(fieldNames.INSURANCE_TYPE, selectedItem.insuranceType);
        setValue(fieldNames.MAX_INSTALLMENTS, selectedItem.maxInstallments);
        setValue(fieldNames.DELAY_PERCENTAGE, selectedItem.delayPercentage);
        setValue(fieldNames.INTEREST_PERCENTAGE, selectedItem.interestPercentage);

        if (selectedItem.paymentType === PaymentTypeEnum.Boleto && boletoComponentEditFields.current) {
            const currentPaymentCondition = productConfig.paymentConditions.find(x => x.id === selectedItem.id);
            const enableBankAgreement = currentPaymentCondition.bankAgreements.find(x => x.enable);
            selectedItem.wallets = enableBankAgreement.wallets;

            setValue(fieldNames.WALLETS, selectedItem.wallets);

            boletoComponentEditFields.current(selectedItem);
        }

        if (creditCardComponentEditFields.current) {
            creditCardComponentEditFields.current(selectedItem);
        }
    }

    const handleDeletePaymentConditionConfig = async (configurationToDelete: IRowPaymentConditionConfig) => {
        setOpenDeleteConfirmationState(false);

        let paymentConditionConfigDtos = tableDataState.rows as unknown as PaymentConditionConfigDto[];

        paymentConditionConfigDtos = paymentConditionConfigDtos.filter(item => (item.id !== configurationToDelete.id));

        const newTableDataState = new PaymentConditionTableStructure(paymentConditionConfigDtos, paymentConditionConfigDtos.length);

        productConfig.paymentConditions = productConfig.paymentConditions.filter(x => x.id !== configurationToDelete.id);

        setTableDataState(newTableDataState);
    }

    const paymentTypes = () => {
        let paymentTypes = []
        for (let paymentTypeEnum of Object.values(PaymentTypeEnum)) {
            paymentTypes.push({
                text: PaymentTypeEnumLabel.get(paymentTypeEnum),
                value: paymentTypeEnum
            })
        }
        return paymentTypes;
    }

    const insuranceTypes = () => {
        let insuranceTypes = []

        for (let insuranceType of Object.values(InsuranceTypeEnum)) {
            insuranceTypes.push({
                text: InsuranceTypeLabel.get(insuranceType),
                value: insuranceType
            })
        }
        return insuranceTypes;
    }

    const onPaymentTypeChange = (event) => {
        setCurrentPaymentType(event.target.value)
    }

    const completeTable = () => {

        if (!productConfig.paymentConditions.length) {
            setTableDataState(new PaymentConditionTableStructure(new Array<PaymentConditionConfigDto>(), 0))
            return;
        }

        productConfig.paymentConditions.forEach((paymentCondition, index) => {
            const id = (index + 1).toString();
            paymentCondition.id = id;
            const paymentConditionDto = new PaymentConditionConfigDto(id, paymentCondition);
            addNewPaymentCondition(paymentConditionDto);
        });
    }

    useEffect(() => {

        completeTable();

    }, [productConfig])

    return (
        <>
            <Box className={classes.root}>
                <Card>
                    <form>
                        <Grid container className={classes.inputValues}>
                            <Grid container justifyContent="space-between" className={classes.inputValuesHeader}>
                                <Typography variant="subtitle1" component="h2">
                                    <Box fontWeight="bold">Forma de Pagamento</Box>
                                    <Divider className={classes.divider} />
                                </Typography>
                                <Button
                                    className={classes.inputValuesCommand}
                                    color="primary"
                                    title="Salvar forma de pagamento"
                                    variant="outlined"
                                    onClick={handleSubmit(addNewPaymentCondition)}
                                    startIcon={<AddCircle />}
                                >
                                    Salvar forma de pagamento
                                </Button>
                            </Grid>

                            <Grid item md={3} className={classes.grid}>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Formas de Pagamentos
                                </InputLabel>
                                <NativeSelect
                                    {...register(fieldNames.PAYMENT_TYPE)}
                                    className={classes.selector}
                                    defaultValue={fieldDefaultValues.PAYMENT_TYPE}
                                    onChange={onPaymentTypeChange}
                                >
                                    {paymentTypes().map(item => {
                                        return (<option key={item.value} value={item.value}>{item.text}</option>);
                                    })}
                                </NativeSelect>
                            </Grid>

                            <Grid item md={3} className={classes.grid}>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Tipo de Seguro
                                </InputLabel>
                                <NativeSelect
                                    {...register(fieldNames.INSURANCE_TYPE)}
                                    className={classes.selector}
                                    defaultValue={fieldDefaultValues.INSURANCE_TYPE}
                                >
                                    {insuranceTypes().map(item => {
                                        return (<option key={item.value} value={item.value}>{item.text}</option>);
                                    })}
                                </NativeSelect>
                            </Grid>

                            <Grid item md={3} className={classes.grid}>
                                <TextField
                                    {...register(fieldNames.MAX_INSTALLMENTS, {
                                        required: true,
                                        min: 1
                                    })}
                                    className={classes.inputValue}
                                    defaultValue={fieldDefaultValues.MAX_INSTALLMENTS}
                                    inputProps={{ maxLength: 3 }}
                                    id="standard-basic"
                                    label="Quantidade máxima de parcelas"
                                    variant="standard"
                                    type="number" />
                                {errors?.maxInstallments?.type === "required" && <p className={classes.validationMessage}>Informe a Quantidade máxima de parcelas</p>}
                                {errors?.maxInstallments?.type === "min" && <p className={classes.validationMessage}>Quantidade deve ser maior que zero</p>}
                            </Grid>

                            <Grid item md={3} className={classes.grid}>
                                <TextField
                                    {...register(fieldNames.INTEREST_PERCENTAGE, {
                                        required: true,
                                        min: 0,
                                        max: 100
                                    })}
                                    defaultValue={fieldDefaultValues.INTEREST_PERCENTAGE}
                                    className={classes.inputValue}
                                    inputProps={{ maxLength: 3 }}
                                    id="standard-basic"
                                    label="Porcentagem de multa"
                                    variant="standard"
                                    type="number" />
                                {errors?.interestPercentage?.type === "required" && <p className={classes.validationMessage}>Informe o percentual de multa</p>}
                                {errors?.interestPercentage?.type === "min" && <p className={classes.validationMessage}>Percentual deve ser maior ou igual a zero</p>}
                                {errors?.interestPercentage?.type === "max" && <p className={classes.validationMessage}>Percentual máximo permitido é 100%</p>}
                            </Grid>

                            <Grid item md={3} className={classes.grid}>
                                <TextField
                                    {...register(fieldNames.DELAY_PERCENTAGE, {
                                        required: true,
                                        min: 0,
                                        max: 100
                                    })}
                                    defaultValue={fieldDefaultValues.DELAY_PERCENTAGE}
                                    className={classes.inputValue}
                                    inputProps={{ maxLength: 3 }}
                                    id="standard-basic"
                                    label="Porcentagem de atraso"
                                    variant="standard"
                                    type="number" />
                                {errors?.delayPercentage?.type === "required" && <p className={classes.validationMessage}>Informe o percentual de atraso</p>}
                                {errors?.delayPercentage?.type === "min" && <p className={classes.validationMessage}>Percentual deve ser maior ou igual a zero</p>}
                                {errors?.delayPercentage?.type === "max" && <p className={classes.validationMessage}>Percentual máximo permitido é 100%</p>}
                            </Grid>

                            {[PaymentTypeEnum.CreditCardCheckout, PaymentTypeEnum.CreditCardRecurrence].includes(currentPaymentType) &&
                                <CreditCardFieldsComponent registerForm={register} setValue={setValue} onClearFields={creditCardComponentClearFields} onEditFields={creditCardComponentEditFields} />
                            }

                            {currentPaymentType === PaymentTypeEnum.Boleto &&
                                <BoletoFieldsComponent registerForm={register}
                                    setValue={setValue}
                                    onClearFields={boletoComponentClearFields}
                                    onEditFields={boletoComponentEditFields}
                                    productConfig={productConfig}
                                    createBankAgreement={createBankAgreement}
                                    currentPaymentConditionId={currentPaymentIdState} />
                            }

                            <Grid item xs={10} className={classes.buttonSwitch}>
                                <SwitchButton
                                    label="É a forma de pagamento principal?"
                                    checked={isDefaultPaymentType}
                                    onChange={(event) => {
                                        setIsDefaultPaymentType(Boolean(event.target.checked));
                                    }}
                                />
                            </Grid>

                            <Grid item xs={10} className={classes.buttonSwitch}>
                                <SwitchButton
                                    label="Habilitar nos Portais?"
                                    checked={enableOnPortal}
                                    onChange={(event) => {
                                        setEnableOnPortal(Boolean(event.target.checked));
                                    }}
                                />
                            </Grid>

                            <DynamicTable
                                data={tableDataState}
                                actionEditClick={(props) => actionSelectPaymentConditionConfig(props)}
                                actionDeleteClick={(props) => actionDelete(props)}
                            />
                        </Grid>
                    </form>

                    <MessageBox
                        opened={openDeleteConfirmationState}
                        handleClose={() => setOpenDeleteConfirmationState(false)}
                        handlePrimary={() => handleDeletePaymentConditionConfig(paymentConditionConfigToDelete)}
                        handleSecondary={() => setOpenDeleteConfirmationState(false)}
                        type='warning'
                        title="Excluir configuração de pagamento"
                        text="Deseja Excluir? Ao clicar em Confirmar todas as informações desta configuração serão perdidas!"
                    />

                    <MessageBox
                        opened={paymentConditionDuplicatedMessageOpenedState}
                        handleClose={() => {
                            setPaymentConditionDuplicatedMessageOpenedState(false)
                        }}
                        handleSecondary={() => {
                            setPaymentConditionDuplicatedMessageOpenedState(false)
                        }}
                        labelSecondary="Fechar"
                        type='warning'
                        title="Não foi possível incluir a forma de pagamento"
                        text="Forma de pagamento de já cadastrada para o Tipo de Seguro selecionados"
                    />

                </Card>
            </Box>
        </>
    )
}
