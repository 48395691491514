import { makeUserManager } from 'react-oidc'
import { WebStorageStateStore } from 'oidc-client'
import { environmentVariables } from '@shared/configs/environment-variables'

const configManager = {
  scope: environmentVariables.SCOPE,
  client_id: environmentVariables.CLIENT_ID,
  authority: environmentVariables.AUTHORITY_URL,
  redirect_uri: environmentVariables.REDIRECT_URI,
  response_type: environmentVariables.AUTHORITY_RESPONSE_TYPE,
  post_logout_redirect_uri: environmentVariables.POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: environmentVariables.SILENT_RENEW_REDIRECT_URI,
  loadUserInfo: true,
  monitorSession: true,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

export default makeUserManager(configManager)
