export class ApiException extends Error {

  status: number;

  message: string;

  constructor(message: string, status: number = 0) {
    super();
    this.status = status;
    this.message = message;
  }
}