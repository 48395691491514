import { useEffect } from 'react'
import useSecurity from '@security/user-security'
import SplashScreen from '@components/splash-screen'

export const Signin = () => {
  const { signin } = useSecurity()

  useEffect(() => {
    signin();
  }, [signin])

  return <SplashScreen />
}
