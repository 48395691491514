import HeaderNavigation from '@components/header-navigation';

const Master = ({ children }) => {
  return (
    <>
      <HeaderNavigation />
      {children}
    </>
  )
}

export default Master
