import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid } from "@material-ui/core";
import { SaveProductConfigPageServices } from "@pages/save-product-config/services";
import MessageBox from "@components/message-box/message.box";
import { ProductConfig } from "@models/product-config/product-config.model";
import {
  ProductComponent,
  IofConfigComponent,
  ChannelConfigComponent,
} from "@components/index";
import { useParams } from "react-router-dom";
import { ApiException } from "@clients/api-exception";
import { MessageBoxConfiguration } from "@pages/save-product-config/dtos/message-box-error-configuration.dto";
import { routePaths } from "@shared/configs/constants/routes-path";
import { useNavigate } from "react-router-dom";
import useSecurity from "@security/user-security";
import { UserSecurity } from "@models/user-security/user-security.model";
import useStyles from "@pages/save-product-config/style";
import { ProductPaymentConditionComponent } from "@components/product-config/product-payment-condition/product-payment-condition-component";
import { useActiveHeader, useSubHeader } from "@pottencial-tangerine/ui";

export const PageSaveProductConfig = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { productId } = useParams();
  const { getUserProfile } = useSecurity();
  useActiveHeader("products");
  useSubHeader({
    title: productId ? "Editar produto" : "Cadastrar produto",
    breadcrumb: {
      home: () => navigate("/home"),
      options: [
        { label: "Produtos", href: () => navigate("/products") },
        { label: productId ? "Editar produto" : "Cadastrar produto" }
      ],
    },
  });

  const setProductConfig = async () => {
    if (!productId || productConfig.productId) return;

    SaveProductConfigPageServices.getProductConfig(productId)
      .then((productConfig) => {
        setProductConfigState(productConfig);
      })
      .catch((error: ApiException) => {
        setMessageBoxErrorState(
          MessageBoxConfiguration.getDefaultMessageBox(
            error.message,
            redirectToPageProducts
          )
        );
      });
  };

  const cleanMessageBoxErrorConfiguration = () => {
    setMessageBoxErrorState(
      MessageBoxConfiguration.getDefaultMessageBox(
        "",
        cleanMessageBoxErrorConfiguration
      )
    );
  };

  const redirectToPageProducts = () => {
    cleanMessageBoxErrorConfiguration();
    navigate(routePaths.PRODUCTS);
  };

  const onSave = () => {
    const message = SaveProductConfigPageServices.validate(productConfig);

    if (message) {
      setMessageBoxErrorState(
        MessageBoxConfiguration.getDefaultMessageBox(
          message,
          cleanMessageBoxErrorConfiguration
        )
      );
      return;
    }

    const userProfile = getUserProfile() as UserSecurity;

    SaveProductConfigPageServices.saveProductConfig(
      productConfig,
      userProfile.email
    )
      .then(() => {
        setMessageBoxErrorState(
          MessageBoxConfiguration.getSaveConfigurationMessageBox(
            redirectToPageProducts
          )
        );
      })
      .catch((error: ApiException) => {
        setMessageBoxErrorState(
          MessageBoxConfiguration.getDefaultMessageBox(
            error.message,
            cleanMessageBoxErrorConfiguration
          )
        );
      });
  };

  const onCancel = () => {
    setMessageBoxErrorState(
      MessageBoxConfiguration.getCancelConfigurationMessageBox(
        cleanMessageBoxErrorConfiguration,
        redirectToPageProducts
      )
    );
  };

  // States
  const [messageBoxErrorState, setMessageBoxErrorState] = useState(
    MessageBoxConfiguration.getDefaultMessageBox(
      "",
      cleanMessageBoxErrorConfiguration
    )
  );
  const [productConfig, setProductConfigState] = useState(new ProductConfig());

  useEffect(() => {
    setProductConfig();
  }, []);

  return (
    <Box p={4} my={10}>
      <Grid container justifyContent="space-between">
        <ProductComponent
          productConfig={productConfig}
          editingProductConfig={!!productId}
        />
        <IofConfigComponent productConfig={productConfig} />
        <ChannelConfigComponent productConfig={productConfig} />
        <ProductPaymentConditionComponent productConfig={productConfig} />
      </Grid>

      <br />

      <Grid container justifyContent="flex-end">
        <Button
          className={[classes.button, classes.cancelButton].join(" ")}
          title="Cancelar"
          variant="outlined"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          className={[classes.button, classes.saveButton].join(" ")}
          title="Salvar"
          variant="outlined"
          onClick={onSave}
        >
          Salvar
        </Button>
      </Grid>

      <MessageBox
        opened={!!messageBoxErrorState.message}
        thumb={messageBoxErrorState.icon}
        handleClose={() => messageBoxErrorState.handleClose()}
        labelPrimary={messageBoxErrorState.labelPrimary}
        handlePrimary={
          messageBoxErrorState.handlePrimary
            ? () => messageBoxErrorState.handlePrimary()
            : null
        }
        labelSecondary={messageBoxErrorState.labelSecondary}
        handleSecondary={() => messageBoxErrorState.handleSecondary()}
        title={messageBoxErrorState.title}
        text={messageBoxErrorState.message}
      />
    </Box>
  );
};
