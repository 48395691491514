import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import EventEmitter from 'events'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import logo from './logo.gif'

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#00000030',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
}))

const emitter = new EventEmitter()

const LOADER_ENABLE = 'loader.enable'
const LOADER_DISABLE = 'loader.disable'

const Loader = ({ show }) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(0)

  useEffect(() => {
    const enable = () => setDisabled((count) => --count)
    emitter.on(LOADER_ENABLE, enable)

    const disable = () => setDisabled((count) => ++count)
    emitter.on(LOADER_DISABLE, disable)

    return () => {
      emitter.removeListener(LOADER_ENABLE, enable)
      emitter.removeListener(LOADER_DISABLE, disable)
    }
  }, [])

  return (
    <>
      {disabled === 0 && show && (
        <Box className={classes.root}>
          <Card>
            <CardContent>
              <Box p={1}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  direction="column"
                  alignItems="center"
                >
                  <Grid item>
                    <Box>
                      <img width="50" alt="Pottencial Seguradora" src={logo} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography align="center" variant="h6">
                      Carregando...
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

export const useLoader = () => {
  const enableLoader = useCallback(() => emitter.emit(LOADER_ENABLE), [])
  const disableLoader = useCallback(() => emitter.emit(LOADER_DISABLE), [])

  return {
    enableLoader,
    disableLoader,
  }
}

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Loader
