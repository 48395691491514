import GetProductResponse from "@clients/product/dtos/get-product.response.dto";
import { ProductClient } from "@clients/product/product.client";

const getProducts = async (): Promise<GetProductResponse> => {
  const response = await ProductClient.getProducts();
  const productResponse = response as unknown as GetProductResponse;
  return productResponse
}

export const ProductComponentServices = {
    getProducts
}