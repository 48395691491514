export enum PaymentProcessingTimeType {
    Undefined,
    ProposalAcceptance,
    PolicyIssuance
}

export const PaymentProcessingTimeTypeLabel = new Map<number, string>([
    [PaymentProcessingTimeType.Undefined, 'Indefinido'],
    [PaymentProcessingTimeType.ProposalAcceptance, 'Aceitação de proposta'],
    [PaymentProcessingTimeType.PolicyIssuance, 'Emissão de Apólice'],
])

export const PaymentProcessingTimeTypeNumber = new Map<string, PaymentProcessingTimeType>([
    ['Undefined', PaymentProcessingTimeType.Undefined],
    ['ProposalAcceptance', PaymentProcessingTimeType.ProposalAcceptance],
    ['PolicyIssuance', PaymentProcessingTimeType.PolicyIssuance],
])