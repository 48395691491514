import './App.css';
import Routes from './Routes';
import AxiosSetting from 'helpers/axios';
import { useMemo, useState } from 'react';
import Loader from '@components/loader-box';
import AlertProvider from '@components/alert-box';
import { Bootstrap, EnvEnum } from '@pottencial-tangerine/ui'


function App() {
  const [loading, setLoading] = useState(false)

  const handleError = useMemo(
    () => ({
      401: () => {
        const customEvent = new CustomEvent('app.error', { detail: { status: 401 } })
        window.dispatchEvent(customEvent)
      },
      403: () => {
        const customEvent = new CustomEvent('app.error', { detail: { status: 403 } })
        window.dispatchEvent(customEvent)
      },
    }),
    [],
  )

  return (
    <>
      <AxiosSetting
        handleError={handleError}
        onStartRequest={() => setLoading(true)}
        onStopRequest={() => setLoading(false)}
      />
      <Loader show={loading} />
      <AlertProvider />

      <Bootstrap env={EnvEnum.DEV}>
        <Routes />
      </Bootstrap>
    </>
  );
}

export default App;
