import { ProductConfig } from "@models/product-config/product-config.model";
import { IColumnTableProductConfig } from "@pages/product-config/interfaces/table.column.interface";
import { IRowTableProductConfig } from "@pages/product-config/interfaces/table.row.interface";

export class ProductConfigTableStructure {

  columns: Array<IColumnTableProductConfig> = [];

  rows: Array<IRowTableProductConfig> = [];

  totalRecords: number;

  constructor(productConfigs: Array<ProductConfig>, totalRecords: number) {
    this.totalRecords = totalRecords;
    this.columns = [
      { name: 'Product ID', visible: true },
      { name: 'Descrição', visible: true },
      { name: 'Ramo', visible: true },
      { name: 'Permite cancel. de déb. em aberto', visible: true },
      { name: 'Permite cancel. automático', visible: true },
      { name: 'Qtde de config. de IOFs', visible: true },
      { name: 'Qtde de formas de pagamento', visible: true }
    ];

    if (!productConfigs)
      productConfigs = new Array<ProductConfig>();

    productConfigs.forEach(productConfig => {

      const row = {
        id: productConfig.productId,
        description: productConfig.name,
        branch: productConfig.branch,
        allowCancellationDebt: productConfig.allowCancellationDebt ? "Permite" : "Não Permite",
        allowAutomaticCancel: productConfig.allowAutomaticCancel ? "Permite" : "Não Permite",
        qtdeIofs: productConfig.iofConfigs?.length ?? 0,
        qtdePayments: productConfig.paymentConditions?.length ?? 0
      } as IRowTableProductConfig;

      this.rows.push(row);
    })
  }
}