import axios from "axios";
import { ApiException } from "@clients/api-exception";
import { GetUserResponse } from "@clients/user/dtos/get-user.response.dto";

const verifyUserAccess = async (userName: string): Promise<GetUserResponse | ApiException> => {
  try {
    const response = await axios.get(`/api/v1/users/${userName}`);   
    return response.data;
  } catch (err) {
    return new ApiException(err.message || 'Erro ao obter dados', err.status);
  }
}

export const UserClient = {
  verifyUserAccess,
}