import { Box, Button, Card, Divider, Grid, InputLabel, NativeSelect, Typography } from '@material-ui/core';
import useStyles from '@components/product-config/iof-config/style';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { DynamicTable } from '@components/dynamic-table/dynamic-table';
import { IRowTableIofConfig } from '@components/product-config/iof-config/interfaces/table.row.interface';
import { IofConfigTableStructure } from '@components/product-config/iof-config/dtos/table-structure.dto';
import { AddCircle } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import IofConfigDto from '@components/product-config/iof-config/dtos/iof-config.dto';
import { InsuranceTypeEnum, InsuranceTypeLabel, ModalityType, ModalityTypeLabel, RefundIof, RefundIofLabel } from 'enums';
import React, { useEffect } from 'react';
import icoWarning from '@assets/img/ico-alert-warning.svg';
import MessageBox from '@components/message-box';
import IofConfig from '@models/product-config/iof-config.model';
import SwitchButton from '@components/switch-button/switch-button';
import IIofConfigComponentProps from '@components/product-config/iof-config/interfaces/iof-config.components.props.interface';

const emptyTableState = new IofConfigTableStructure(new Array<IofConfigDto>(), 0);

const modalityTypes = [
  {
    text: ModalityTypeLabel.get(ModalityType.Undefined),
    value: ModalityType.Undefined
  },
  {
    text: ModalityTypeLabel.get(ModalityType.Traditional),
    value: ModalityType.Traditional
  },
  {
    text: ModalityTypeLabel.get(ModalityType.Monthly),
    value: ModalityType.Monthly
  },
  {
    text: ModalityTypeLabel.get(ModalityType.ExemptIof),
    value: ModalityType.ExemptIof
  },
]

const insuranceTypes = [
  {
    text: InsuranceTypeLabel.get(InsuranceTypeEnum.Insurance),
    value: InsuranceTypeEnum.Insurance
  },
  {
    text: InsuranceTypeLabel.get(InsuranceTypeEnum.AcceptedCoinsurance),
    value: InsuranceTypeEnum.AcceptedCoinsurance
  },
]

const fieldNames = {
  ID: 'id',
  IOF_PERCENTAGE: 'iofPercentage',
  MODALITY_TYPE: 'modalityType',
  INSURANCE_TYPE: 'insuranceType',
  REFUND_IOF: 'refundIof'
}

const fieldDefaultValues = {
  ID: null,
  IOF_PERCENTAGE: '',
  MODALITY_TYPE: ModalityType.Traditional,
  INSURANCE_TYPE: InsuranceTypeEnum.Insurance,
  REFUND_IOF: false
}

export const IofConfigComponent: React.FC<IIofConfigComponentProps> = ({ productConfig }) => {
  const [tableDataState, setTableDataState] = useState(emptyTableState);
  const [refundIofState, setRefundIofState] = useState(true);
  const [iofConfigDuplicatedMessageOpenedState, setIofConfigDuplicatedMessageOpenedState] = useState(false)
  const [openDeleteConfirmationState, setOpenDeleteConfirmationState] = useState(false)
  const [iofConfigToDelete, setIOFConfigToDelete] = useState(null as IRowTableIofConfig)
  const { handleSubmit, register, setValue, formState: { errors } } = useForm();
  const classes = useStyles();

  const actionSelectIofConfig = (selectedItem: IRowTableIofConfig) => {
    setValue(fieldNames.IOF_PERCENTAGE, selectedItem.iofPercentage);
    setValue(fieldNames.MODALITY_TYPE, selectedItem.modalityType);
    setValue(fieldNames.INSURANCE_TYPE, selectedItem.insuranceType);
    setValue(fieldNames.ID, selectedItem.id);
    setRefundIofState(selectedItem.refundIofDescription === RefundIofLabel.get(Number(RefundIof.Refund)));
  }

  const configurationAlreadyExists = (list: Array<IRowTableIofConfig>, object: IRowTableIofConfig) => {
    var listItems = list.filter(o => Number(o.insuranceType) === Number(object.insuranceType) &&
      Number(o.modalityType) === Number(object.modalityType));

    if (listItems.length === 0) {
      return false;
    }

    if ((listItems.length === 1) && (listItems[0].id === object.id)) {
      return false;
    }

    return true;
  }

  const clearIofFields = () => {
    setValue(fieldNames.IOF_PERCENTAGE, fieldDefaultValues.IOF_PERCENTAGE);
    setValue(fieldNames.MODALITY_TYPE, fieldDefaultValues.MODALITY_TYPE);
    setValue(fieldNames.INSURANCE_TYPE, fieldDefaultValues.INSURANCE_TYPE);
    setValue(fieldNames.ID, fieldDefaultValues.ID);
    setRefundIofState(true);
  }

  const submitForm = (iofConfig: IofConfigDto) => {
    actionAddNewIofConfig(iofConfig, false);
  }

  const actionAddNewIofConfig = (data: IofConfigDto, isLoadingIofConfig: boolean) => {

    if (!isLoadingIofConfig)
      data.refundIof = refundIofState;

    if (!data.id) {
      const newId = (tableDataState.rows.length + 1).toString();
      data.id = newId;
    }

    if (configurationAlreadyExists(tableDataState.rows, data)) {
      setIofConfigDuplicatedMessageOpenedState(true);
      return;
    }

    let iofConfigs = tableDataState.rows as unknown as IofConfigDto[];

    const previousIofConfig = iofConfigs.find(i => i.id === data.id);

    if (!previousIofConfig) {
      iofConfigs.push(data);
    } else {
      Object.assign(previousIofConfig, data);
    }

    const newTableDataState = new IofConfigTableStructure(iofConfigs, iofConfigs.length);

    setIofConfigsOnProductConfig(iofConfigs);

    setTableDataState(newTableDataState);

    clearIofFields();
  }

  const actionDelete = (selectedItem: IRowTableIofConfig) => {
    setIOFConfigToDelete(selectedItem);
    setOpenDeleteConfirmationState(true);
  }

  const onRefundIofChange = (event) => {
    setRefundIofState(Boolean(event.target.checked));
  }

  const setIofConfigsOnProductConfig = (iofConfigs: Array<IofConfigDto>) => {
    productConfig.iofConfigs = new Array<IofConfig>();

    iofConfigs.forEach((iofConfigDto) => {
      const newIofConfig = {
        iofPercentage: iofConfigDto.iofPercentage,
        modalityType: Number(iofConfigDto.modalityType),
        insuranceType: iofConfigDto.insuranceType,
        refundIof: iofConfigDto.refundIof
      } as IofConfig;
      productConfig.iofConfigs.push(newIofConfig);
    })
  }

  const handleDeleteIOFConfig = async (configurationToDelete: IRowTableIofConfig) => {
    setOpenDeleteConfirmationState(false);

    let iofConfigs = tableDataState.rows as unknown as IofConfigDto[];

    iofConfigs = iofConfigs.filter(item => (item.id != configurationToDelete.id));

    const newTableDataState = new IofConfigTableStructure(iofConfigs, iofConfigs.length);

    setIofConfigsOnProductConfig(iofConfigs);

    setTableDataState(newTableDataState);
  }

  const completeTable = () => {

    if (!productConfig?.iofConfigs.length) {
      setTableDataState(new IofConfigTableStructure(new Array<IofConfigDto>(), 0))
      return;
    }

    productConfig.iofConfigs.forEach((iofConfig, index) => {
      const id = (index + 1).toString();
      const iofConfigDto = new IofConfigDto(id, iofConfig);
      actionAddNewIofConfig(iofConfigDto, true);
    });
  }

  useEffect(() => {

    completeTable();

  }, [productConfig])

  return (
    <Box className={classes.root}>
      <Card>
        <form>
          <Grid container className={classes.inputValues}>
            <Grid container justifyContent="space-between" className={classes.inputValuesHeader}>
              <Typography variant="subtitle1" component="h2">
                <Box fontWeight="bold">Configurações de IOF</Box>
                <Divider className={classes.divider} />
              </Typography>
              <Button
                className={classes.inputValuesCommand}
                color="primary"
                title="Gravar configuração"
                variant="outlined"
                onClick={handleSubmit(submitForm)}
                startIcon={<AddCircle />}
              >
                Gravar configuração
              </Button>
            </Grid>

            <Grid item md={3} className={classes.grid}>
              <TextField
                {...register(fieldNames.IOF_PERCENTAGE, {
                  required: true,
                  min: 0,
                  max: 100
                })}
                className={classes.inputValue}
                inputProps={{ maxLength: 5 }}
                id="standard-basic"
                label="Porcentagem de iof"
                variant="standard"
                type="number" />
              {errors?.iofPercentage?.type === "required" && <p className={classes.validationMessage}>Informe o percentual de IOF</p>}
              {errors?.iofPercentage?.type === "min" && <p className={classes.validationMessage}>Percentual deve ser maior ou igual a zero</p>}
              {errors?.iofPercentage?.type === "max" && <p className={classes.validationMessage}>Percentual máximo permitido é 100%</p>}
            </Grid>
            <Grid item md={3} className={classes.grid}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Modalidade
              </InputLabel>
              <NativeSelect
                {...register(fieldNames.MODALITY_TYPE)}
                className={classes.selector}
                defaultValue={fieldDefaultValues.MODALITY_TYPE}
              >
                {modalityTypes.map(item => {
                  return (<option key={item.value} value={item.value}>{item.text}</option>);
                })}
              </NativeSelect>
            </Grid>
            <Grid item md={3} className={classes.grid}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Tipo de seguro
              </InputLabel>
              <NativeSelect
                {...register(fieldNames.INSURANCE_TYPE)}
                className={classes.selector}
                defaultValue={fieldDefaultValues.INSURANCE_TYPE}
              >
                {insuranceTypes.map(item => {
                  return (<option key={item.value} value={item.value}>{item.text}</option>);
                })}
              </NativeSelect>
            </Grid>
            <Grid item md={3} className={classes.grid}>
              <SwitchButton
                label="Restituição de IOF"
                checked={refundIofState}
                onChange={onRefundIofChange}
              />
            </Grid>
          </Grid>
          <DynamicTable
            data={tableDataState}
            actionEditClick={(props) => actionSelectIofConfig(props)}
            actionDeleteClick={(props) => actionDelete(props)}
          />
        </form>

        <MessageBox
          opened={iofConfigDuplicatedMessageOpenedState}
          thumb={icoWarning}
          handleClose={() => {
            setIofConfigDuplicatedMessageOpenedState(false)
          }}
          handleSecondary={() => {
            setIofConfigDuplicatedMessageOpenedState(false)
          }}
          labelSecondary="Fechar"
          title="Não foi possível incluir a configuração de IOF"
          text="Configuração de IOF já cadastrada para Modalidade e Tipo de Seguro selecionados"
        />

        <MessageBox
          opened={openDeleteConfirmationState}
          handleClose={() => setOpenDeleteConfirmationState(false)}
          handlePrimary={() => handleDeleteIOFConfig(iofConfigToDelete)}
          handleSecondary={() => setOpenDeleteConfirmationState(false)}
          type='warning'
          title="Excluir configuração de IOF"
          text="Deseja Excluir? Ao clicar em Confirmar todas as informações desta configuração serão perdidas!"
        />
      </Card>
    </Box>
  )
}
