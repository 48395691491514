import PropTypes from 'prop-types'
import useSecurity from '@security/user-security'
import SplashScreen from '@components/splash-screen'

const PrivateRoute = (props: any) => {
  const { isLoggedIn, signin } = useSecurity()
  const { layout: Layout, component: Component } = props

  if (!isLoggedIn()) {   
    signin()
    return <SplashScreen />
  }

  return (<Layout><Component /></Layout>)
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
}

PrivateRoute.defaultProps = {
  component: undefined,
}

export default PrivateRoute
