import { GetUserResponse } from "@clients/user/dtos/get-user.response.dto";
import { UserClient } from "@clients/user/user.client";

const verifyUserAccess = async (userName: string): Promise<GetUserResponse> => {
  const response = await UserClient.verifyUserAccess(userName);
  const userResponse = response as unknown as GetUserResponse;
  return userResponse;
}

export const PageMenuPrincipalService = {
    verifyUserAccess
}