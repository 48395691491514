import React, { useEffect, useState } from 'react';
import useStyles from '@components/product-config/products/styles';
import { Divider, Grid, Card, Typography, InputLabel } from '@material-ui/core';
import Box from '@mui/material/Box';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import GetProductResponse from '@clients/product/dtos/get-product.response.dto';
import SwitchButton from '@components/switch-button/switch-button';
import { ProductComponentServices } from '@components/product-config/products/services';
import onlyIntegers from '@shared/utils/functions/only-integers.function';
import IProductComponentProps from '@components/product-config/products/interfaces/product.component.props.interface';
import { ApiException } from '@clients/api-exception';
import { ElapsedTableStrategyCalculationType, ElapsedTableStrategyCalculationTypeLabel } from '@enums/elapsed-table-strategy-calculation.enum';

const data: GetProductResponse = {
  products: [],
  totalRecords: 0
}

export const ProductComponent: React.FC<IProductComponentProps> = ({ productConfig, editingProductConfig }) => {

  const classes = useStyles()
  const [checkedState, setCheckedState] = useState(true)
  const [checkedAutomaticCancelationState, setCheckedAutomaticCancelationState] = useState(false)
  const [selectValueState, setSelectValueState] = useState("")
  const [listProductsState, setListProductsState] = useState(data)
  const [depositState, setDepositState] = useState<number | null>(null)
  const [selectElapsedTableStrategyState, setSelectElapsedTableStrategyState] = useState<ElapsedTableStrategyCalculationType>(ElapsedTableStrategyCalculationType.Lower)

  const delayNotification = depositState ? depositState : "";

  const getProductsConfigs = async () => {
    ProductComponentServices.getProducts()
      .then((result: GetProductResponse | ApiException) => {
        if (result instanceof ApiException) {
          setListProductsState(data)
        } else {
          setListProductsState(result)
        }
      })
  }

  const onBlurSelectProduct = (event) => {
    setSelectValueState(event.target.value)
    productConfig.productId = (event.target.value === "0") ? null : event.target.value;
  }

  const onBlurDelayNotification = (event) => {
    productConfig.delayNotification.delayNotificationDays = event.target.value;
  }

  const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    productConfig.allowCancellationDebt = event.target.checked;
    setCheckedState(event.target.checked);
  }

  const onChangeAutomaticCancelationSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    productConfig.allowAutomaticCancel = event.target.checked;
    setCheckedAutomaticCancelationState(event.target.checked);
  }

  const onChangeDelayNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.substring(0, 4);
    setDepositState(onlyIntegers(value))
  }

  useEffect(() => {
    setSelectValueState(productConfig?.productId);
    setDepositState(productConfig.delayNotification?.delayNotificationDays);
    setCheckedState(productConfig.allowCancellationDebt);
    setCheckedAutomaticCancelationState(productConfig.allowAutomaticCancel);
    setSelectElapsedTableStrategyState(productConfig.elapsedTableStrategyCalculation ?? ElapsedTableStrategyCalculationType.Lower)
    getProductsConfigs();
  }, [productConfig]);

  return (
    <Box className={classes.box} sx={{ flexGrow: 1 }}>
      <Card className={classes.card}>

        <Grid container md={12}>
          <Typography variant="subtitle1" component="h2" className={classes.typography}>
            <Box fontWeight="bold">Produto</Box>
            <Divider className={classes.divider} />
          </Typography>
        </Grid>

        <Grid container>

          <Grid item md={4} className={classes.grid}>
            <NativeSelect
              onChange={(e => setSelectValueState(e.target.value))}
              onBlur={onBlurSelectProduct}
              className={classes.nativeSelect}
              value={selectValueState}
              disabled={editingProductConfig}
            >
              <option value="0">Selecione o Produto</option>
              {
                listProductsState.products.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)
                  .map(X => <option key={X.id} value={X.id}>{`${X.branch} - ${X.name}`}</option>)
              }
            </NativeSelect>
          </Grid>

          <Grid item md={4} className={classes.grid}>
            <TextField
              id="standard-basic"
              variant='standard'
              fullWidth
              value={delayNotification}
              onChange={onChangeDelayNotification}
              onBlur={onBlurDelayNotification}
              label='Dias para notificação em atraso'
            />
          </Grid>

          <Grid item md={4} className={classes.grid}>
            <SwitchButton label="Permite cancelamento com débitos em aberto" checked={checkedState} onChange={onChangeSwitch} />
          </Grid>

        </Grid>

        <Grid container>

          <Grid item md={4} className={classes.grid}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Prazo decorrido a ser utilizado para valores não previstos na tabela
            </InputLabel>
            <NativeSelect
              onChange={(e => setSelectElapsedTableStrategyState((e.target.value as ElapsedTableStrategyCalculationType)))}
              onBlur={(e => {
                productConfig.elapsedTableStrategyCalculation = (e.target.value as ElapsedTableStrategyCalculationType);
              })}
              className={classes.nativeSelect}
              defaultValue={selectElapsedTableStrategyState}
              value={selectElapsedTableStrategyState}
            >
              {
                Object.keys(ElapsedTableStrategyCalculationType).map(x => {
                  const label = ElapsedTableStrategyCalculationTypeLabel.get(x);
                  return <option key={x} value={x}>{`${label}`}</option>
                })
              }
            </NativeSelect>
          </Grid>

          <Grid item md={4} className={classes.grid}></Grid>

          <Grid item md={4} className={classes.grid}>
            <SwitchButton label="Permite cancelamento Automático" checked={checkedAutomaticCancelationState} onChange={onChangeAutomaticCancelationSwitch} />
          </Grid>

        </Grid>

      </Card>
    </Box >
  );
}

