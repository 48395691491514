import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
          justifyContent: 'center',
          '& :nth-child(1)': {
            order: 2,
          },
          '& :nth-child(2)': {
            order: 3,
          },
          '& :nth-child(3)': {
            order: 1,
          },
        },
      },
      containerTheme: {
        [theme.breakpoints.up('md')]: {
          padding: '0 10px',
          margin: '0 10px',
          border: `solid ${theme.palette.primary.contrastText}`,
          borderWidth: '0 1px 0 0',
        },
      },
      btn: {
        color: theme.palette.primary.contrastText,
        '& .MuiIcon-root': {
          fontSize: '1.25rem',
        },
      },
}))