import { IData } from "@components/dynamic-table/intefaces/data.interface";
import { DocumentTypeLabel, PaymentProcessingTimeTypeLabel } from "@enums/index";
import { IColumnWalletTable } from "../interfaces/wallet.table.column.interface";
import { IRowWallet } from "../interfaces/wallet.table.row.interface";
import WalletDto from "./wallet.dto";

export class WalletTableStructure implements IData {

    columns: Array<IColumnWalletTable> = [];

    rows: Array<IRowWallet> = [];

    totalRecords: number;

    constructor(rowWallets: Array<WalletDto>, totalRecords: number) {
        this.totalRecords = totalRecords;
        this.columns = [
            { name: 'id', visible: false },
            { name: 'Código da Carteira', visible: true },
            { name: 'Número da carteira', visible: true },
            { name: 'Agência', visible: true },
            { name: 'documentType', visible: false },
            { name: 'tipo de documento', visible: true },
            { name: 'Número do contrato', visible: true },
            { name: 'Número da conta', visible: true },
            { name: 'Dígito da conta', visible: true },
            { name: 'paymentProcessingTimeType', visible: false },
            { name: 'Tipo de tempo de processamento de pagamento', visible: true },
            { name: 'Limite de dias após a expiração', visible: true },
        ];

        this.rows = new Array<IRowWallet>();

        rowWallets.forEach(rowWallet => {
            let paymentProcessingTimeTypeNumber = Number(rowWallet.paymentProcessingTimeType);
            let documentTypeNumber = Number(rowWallet.documentType);

            const row = {
                id: rowWallet.id,
                walletCode: rowWallet.walletCode,
                walletNumber: rowWallet.walletNumber,
                agencyNumber: rowWallet.agencyNumber,
                documentType: Number(rowWallet.documentType),
                documentTypeDescription: DocumentTypeLabel.get(documentTypeNumber),
                agreementNumber: rowWallet.agreementNumber,
                accountNumber: rowWallet.accountNumber,
                accountNumberDigit: rowWallet.accountNumberDigit,
                paymentProcessingTimeType: paymentProcessingTimeTypeNumber,
                paymentProcessingTimeTypeDescription: PaymentProcessingTimeTypeLabel.get(paymentProcessingTimeTypeNumber),
                limitDaysAfterExpiration: rowWallet.limitDaysAfterExpiration,
            } as IRowWallet;
            
            this.rows.push(row);
        })
    }
}
