import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: { 
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#ffffff',
    height: '80px',
    padding: '0 40px 0 14px',
    boxShadow: '0 0 1rem rgb(188, 186, 186)',
  },
  logo: {
    width: 200,
    maxWidth: '100%'   
  },
  leftHeader: {
    cursor: 'pointer',
    justifyContent: 'space-between'
  },
  rightHeader: {
    width: '100%'
  },
  menuOptions: {
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
    margin: '0 0 0 2rem',
  
    a: {
      textDecoration: 'none'
    },
  
    li: {
      fontSize: '14px',
      textTransform: 'uppercase' as const,
      color: '#000000',
      padding: '10px 10px 2px',
      cursor: 'pointer',
      fontWeight: 300,
      lineHeight: '21px',
      fontFamily: '"Poppins", sans-serif',
      borderBottom: '2px solid transparent',
  
      '&:hover': {
        borderColor: '#fff',
        fontWeight: 600,
        borderBottom: '0.18rem solid #f26522',
      }
    }
  },
  inactiveMenu:{
    fontSize: '14px',
    textTransform: 'uppercase' as const,
    color: '#000000',
    padding: '10px 10px 2px',
    cursor: 'pointer',
    fontWeight: 300,
    lineHeight: '21px',
    fontFamily: '"Poppins", sans-serif',
    borderBottom: '2px solid transparent',

    '&:hover': {
      borderColor: '#fff',
      fontWeight: 600,
      borderBottom: '0.18rem solid #f26522',
    }
  },
  logoutDiv: {
    width: '100%',
    alignSelf: 'flex-end',
  },
  activeMenu: {
    fontSize: '14px',
    color: '#000000',
    padding: '10px 10px 2px',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    borderBottom: '0.18rem solid #f26522',
    textTransform: 'uppercase' as const,
  },
}))