import useStyles from '@components/splash-screen/style'
import logo from '@assets/img/logo.svg'
import { Box, CircularProgress } from '@material-ui/core'

function SplashScreen() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        mb={6}
      >
        <img
          className={classes.logo}
          alt="Logo"
          src={logo}
        />
      </Box>
      <CircularProgress className={classes.circularProgress} />
    </div>
  )
}

export default SplashScreen
