import { Box, Button, Typography } from '@material-ui/core';
import userSecurity from '@security/user-security'
import useStyles from '@pages/unauthorized/style'

export const PageUnauthorized = () => {
  const classes = useStyles()
  const { signout } = userSecurity()

  const sair = () => signout('')

  return (
    <>
      <Box className={classes.root}>
        <Typography variant="h5">
          <Box>Você não tem permissão para acessar esse sistema.</Box>
          <Box>
            Entre em contato com o Administrador
          </Box>
        </Typography>

        <Button
          color="primary"
          variant="contained"
          onClick={() => sair()}
          title="Sair"
        >
          Sair
        </Button>
      </Box>
    </>
  )
}