import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
    box: {
        width:"100%",
        float:"left",
        paddingBottom:"1%"
    },
    nativeSelect:{
        width:"100%",
        paddingLeft:"2%",
        paddingRight:"2%",
        paddingTop:"4%"
    },
    grid: {
        width:"100%",
        paddingTop:"1%",
        paddingLeft:"2%",
        paddingRight:"2%",
    },
    divider: {
        width: 60,
        background: '#F26522',
        padding: 0.5,
        height: 2,
    },
    div:{
        border: "1px solid green",
        borderRadius:"5%"
    },
    typography:{
        paddingLeft:"2%"
    },
    card:{
        width:"100%",
        paddingBottom:"2%"
    }
}));

