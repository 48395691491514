import icoWarning from '@assets/img/ico-alert-warning.svg';
import icoSuccess from '@assets/img/ico-alert-success.svg';
import icoAlternative from '@assets/img/ico-alert-alternative.svg';
import { PageSaveProductConfigMessages } from "./messages.dto";

export class MessageBoxConfiguration {

  icon: string;
  title: string;
  message: string;
  labelPrimary: string;
  labelSecondary: string;
  handleClose: Function;
  handlePrimary: Function;
  handleSecondary: Function;

  constructor(
    message: string,
    handleClose: Function,
    labelPrimary: string,
    handlePrimary: Function,
    labelSecondary: string,
    handleSecondary: Function,
    title: string,
    icon: string) {

    this.icon = icon;
    this.title = title;
    this.message = message;
    this.labelPrimary = labelPrimary;
    this.labelSecondary = labelSecondary;
    this.handleClose = handleClose;
    this.handlePrimary = handlePrimary;
    this.handleSecondary = handleSecondary;
  }

  static getDefaultMessageBox(message: string, handleClose: Function): MessageBoxConfiguration {
    return new MessageBoxConfiguration(
      message,
      handleClose,
      "",
      null,
      "Fechar",
      handleClose,
      PageSaveProductConfigMessages.MESSAGE_BOX_ERROR_TITLE,
      icoWarning
    );
  }

  static getCancelConfigurationMessageBox(
    handleClose: Function,
    handleScondary: Function): MessageBoxConfiguration {
    return new MessageBoxConfiguration(
      PageSaveProductConfigMessages.MESSAGE_BOX_CANCEL_CONFIGURATION_MESSAGE,
      handleClose,
      "Confirmar",
      handleScondary,
      "Cancelar",
      handleClose,
      PageSaveProductConfigMessages.MESSAGE_BOX_CANCEL_CONFIGURATION_TITLE,
      icoAlternative
    );
  }

  static getSaveConfigurationMessageBox(
    handleClose: Function): MessageBoxConfiguration {
    return new MessageBoxConfiguration(
      PageSaveProductConfigMessages.SAVE_PRODUCT_CONFIG_SUCCESS_MESSAGE,
      handleClose,
      "",
      null,
      "Fechar",
      handleClose,
      PageSaveProductConfigMessages.MESSAGE_BOX_SUCCESS_CONFIGURATION_TITLE,
      icoSuccess
    );
  }
}