export class PageSaveProductConfigMessages {

  static readonly MESSAGE_BOX_SUCCESS_CONFIGURATION_TITLE = 'Sucesso';
  static readonly MESSAGE_BOX_ERROR_TITLE = 'Ops! Ocorreu algum problema';
  static readonly MESSAGE_BOX_CANCEL_CONFIGURATION_TITLE = 'Cancelar configuração';
  static readonly SAVE_PRODUCT_CONFIG_SUCCESS_MESSAGE = 'Configuração de produto salva com sucesso!'
  static readonly MESSAGE_BOX_CANCEL_CONFIGURATION_MESSAGE = 'Tem certeza que deseja sair da tela de configuração ? Todas as informações preenchidas serão perdidas.';

  static readonly PRODUCT_FIELD_IS_REQUIRED_MESSAGE = "O campo 'Produto' deve ser preenchido.";
  static readonly IOF_CONFIG_IS_REQUIRED_MESSAGE = "É obrigatório adicionar pelo menos uma 'configuração de iof'";
  static readonly PAYMENT_CONDITION_IS_REQUIRED_MESSAGE = "É obrigatório adicionar pelo menos uma 'forma de pagamento' principal";
  static readonly ENABLE_ON_PORTAL_IS_REQUIRED_MESSAGE = "É obrigatório habilitar pelo menos uma 'forma de pagamento' para ser exibida no portal por tipo de seguro";
  static readonly DELAY_NOTIFICATION_MUST_BE_GREATER_OR_EQUAL_TO_ZERO_MESSAGE = "O campo 'Dias para notificação em atraso' deve ser maior o igual a 0.";
  static readonly DIFFERENTS_INSURANCE_TYPES_BETWEEN_IOF_CONFIG_AND_PAYMENT_CONDITION_MESSAGE = "A quantidade de 'Tipo de Seguro' entre as 'Configurações de Iof' e 'Formas de pagamento' está divergente";
  static readonly DIFFERENTS_DEFAULT_PAYMENT_CONDITION_BY_INSURANCE_TYPE_MESSAGE = "Os tipos de seguro informados ('Cosseguro Aceito', 'Seguro' ou ambos) nas formas de pagamento devem possuir pelo menos uma forma de pagamento principal.";
}
