import { ChannelTypeLabel } from 'enums';
import { IData } from "@components/dynamic-table/intefaces/data.interface";
import ChannelConfigDto from "@components/product-config/channel-config/dtos/channel-config.dto";
import { IRowTableChannelConfig } from "@components/product-config/channel-config/interfaces/table.row.interface";
import { IColumnTableChannelConfig } from "@components/product-config/channel-config/interfaces/table.column.interface";

export class ChannelConfigTableStructure implements IData {

  columns: Array<IColumnTableChannelConfig> = [];

  rows: Array<IRowTableChannelConfig> = [];

  totalRecords: number;

  constructor(channelConfigs: Array<ChannelConfigDto>, totalRecords: number) {
    this.totalRecords = totalRecords;
    this.columns = [
      { name: 'id', visible: false },
      { name: 'ChannelType', visible: false },
      { name: 'Tipo de canal', visible: true  }, 
      { name: 'Url de callback cartão de crédito', visible: true  },  
      { name: 'Url de callback cartão de crédito pós venda', visible: true  },       
    ];

    this.rows = new Array<IRowTableChannelConfig>();
  
    channelConfigs.forEach(channelConfig => {
      const row = {
        id: channelConfig.id,
        channelType: Number(channelConfig.channelType),
        channelTypeDescription: ChannelTypeLabel.get(Number(channelConfig.channelType)),
        creditCardUrlCallback: channelConfig.creditCardUrlCallback,
        creditCardUrlCallbackAfterSale: channelConfig.creditCardUrlCallbackAfterSale,    
      } as IRowTableChannelConfig;

      this.rows.push(row);     
    })
  }
}