import {makeStyles, Theme} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { BorderTop } from '@mui/icons-material'
import { color } from '@mui/system'


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: 'auto'
    },
    root2: {
        width: '100%',
        height: 'auto',
        paddingTop: '20%'       
    },
    divider: {
        width: 60,
        background: '#F26522',
        padding: 0.5,
        height: 2,
    },
    selector: {
        width: "100%",
        paddingLeft: "2%",
        paddingRight: "2%",
        paddingTop: "1%"
    },
    grid: {
        width: '100%',
        paddingTop: '1%',
        paddingLeft: '2%',
        paddingRight: '2%',
    },
    inputValue: {
        width: '100%',
        paddingTop: '1%',
        paddingLeft: '2%',
        paddingRight: '2%',
    },
    inputValues: {
        width: '100%',
        marginBottom: '20px'
    },
    hidden: {
        display: 'none'
    },
    inputValuesHeader: {
        marginBottom: '20px',
        paddingTop: '1%',
        paddingLeft: '2%',
        paddingRight: '2%',
    },
    inputValuesCommand: {
        paddingRight: '2%',
        paddingLeft: '2%',
    },
    buttonSwitch: {
        paddingRight: '2%',
        paddingLeft: '2%',
        marginLeft: '40%',
        display: 'inline-flex',
        alignItems: 'center'
    },
    validationMessage: {
        color: 'red',
        fontSize: '12px',
        height: '8px',
        marginBlockStart: '0',
        marginBlockEnd: '0',
    },
}))
export default useStyles
