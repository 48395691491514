import './index.css';
import App from './App';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store, persist } from './storages';
import { PersistGate } from 'redux-persist/integration/react';
import AppTheme from '@layouts/app-theme';
import { BrowserRouter } from 'react-router-dom';

const Main = () => (
  <>
    <Provider store={store}>
      <PersistGate persistor={persist}>
        <AppTheme>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppTheme>
      </PersistGate>
    </Provider>
  </>
)

ReactDOM.render(
  <Main />, document.getElementById('root'),
)