import { IRoutePaths } from "@shared/configs/constants/routes-path.interface";

export const routePaths: IRoutePaths = {
    HOME: '/',
    SIGNIN: '/signin',
    CALLBACK: '/callback',
    UNAUTHORIZED: '/unauthorized',
    INICIO: '/inicio',
    PRODUCTS: '/products',
    NEW_PRODUCT: '/products/new-product',
    EDIT_PRODUCT: '/products/edit-product/:productId',
    NOT_FOUND_ERROR: '/error/404'
 }