import { IRowTableIofConfig } from "@components/product-config/iof-config/interfaces/table.row.interface";
import { IColumnTableIofConfig } from "@components/product-config/iof-config/interfaces/table.column.interface";
import { IData } from "@components/dynamic-table/intefaces/data.interface";
import IofConfigDto from "@components/product-config/iof-config/dtos/iof-config.dto";
import { InsuranceTypeLabel, ModalityTypeLabel, RefundIofLabel } from 'enums';

export class IofConfigTableStructure implements IData {

  columns: Array<IColumnTableIofConfig> = [];

  rows: Array<IRowTableIofConfig> = [];

  totalRecords: number;

  constructor(iofConfigs: Array<IofConfigDto>, totalRecords: number) {
    this.totalRecords = totalRecords;
    this.columns = [
      { name: 'id', visible: false },
      { name: 'Porcentagem de IOF', visible: true },  
      { name: 'ModalidadeType', visible: false  },   
      { name: 'Modalidade', visible: true  },
      { name: 'InsuranceType', visible: false  },
      { name: 'Tipo de seguro', visible: true  },      
      { name: 'Restituição de IOF', visible: true  },     
      { name: 'refundIOF', visible: false  },   
    ];

    this.rows = new Array<IRowTableIofConfig>();
  
    iofConfigs.forEach(iofConfig => {
      const row = {
        id: iofConfig.id,
        iofPercentage: iofConfig.iofPercentage,
        modalityType: Number(iofConfig.modalityType),       
        modalityDescription: ModalityTypeLabel.get(Number(iofConfig.modalityType)),
        insuranceType: iofConfig.insuranceType,
        insuranceDescription: InsuranceTypeLabel.get(iofConfig.insuranceType),
        refundIofDescription: RefundIofLabel.get(Number(iofConfig.refundIof)),
        refundIof: iofConfig.refundIof,     
      } as IRowTableIofConfig;

      this.rows.push(row);     
    })
  }
}
