import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  button: {
    paddingRight: '1%',
    paddingLeft: '1%',
    width: '10%'
  },
  cancelButton: {
    color: 'white',
    borderColor: '#F26522',
    backgroundColor: '#F26522',
    marginRight: '2%'
  },
  saveButton: {
    color: 'white',
    borderColor: '#5E9D31',
    backgroundColor: '#5E9D31',
  },
}))