import { DocumentTypeEnum, PaymentProcessingTimeType } from "@enums/index";
import { IRowWallet } from "../interfaces/wallet.table.row.interface";
import { Wallet } from "@models/product-config/payment-condition/boleto-payment-condition/wallet.model";
import { DocumentTypeLabel, DocumentTypeNumber } from "@enums/document-type.enum";
import { PaymentProcessingTimeTypeLabel, PaymentProcessingTimeTypeNumber } from "@enums/payment-processing-time-type.enum";

export default class WalletDto implements IRowWallet {
    id: string;
    walletCode: string;
    walletNumber: string;
    agencyNumber: string;
    documentType: DocumentTypeEnum;
    documentTypeDescription: string;
    agreementNumber: string;
    accountNumber: string;
    accountNumberDigit: string;
    paymentProcessingTimeType: PaymentProcessingTimeType;
    paymentProcessingTimeTypeDescription: string;
    limitDaysAfterExpiration: number;

    constructor(id: string, wallet: Wallet) {
        this.id = id;
        this.walletCode = wallet.walletCode;
        this.walletNumber = wallet.walletNumber;
        this.agencyNumber = wallet.agencyNumber;
        this.documentType = DocumentTypeNumber.get(wallet.documentType?.toString()) ?? wallet.documentType;
        this.agreementNumber = wallet.agreementNumber;
        this.accountNumber = wallet.accountNumber;
        this.accountNumberDigit = wallet.accountNumberDigit;
        this.paymentProcessingTimeType = PaymentProcessingTimeTypeNumber.get(wallet.paymentProcessingTimeType?.toString()) ?? wallet.paymentProcessingTimeType;
        this.limitDaysAfterExpiration = wallet.limitDaysAfterExpiration;
        this.documentTypeDescription = DocumentTypeLabel.get(Number(this.documentType));
        this.paymentProcessingTimeTypeDescription = PaymentProcessingTimeTypeLabel.get(Number(this.paymentProcessingTimeType));
    }
}
