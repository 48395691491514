import { IEnvironmentVariables } from "./environment-variables.interface";

export const environmentVariables: IEnvironmentVariables = {
  SCOPE: (window as any).ENV.SCOPE as string,
  BFF_URL: (window as any).ENV.BFF_URL as string,
  CLIENT_ID: (window as any).ENV.CLIENT_ID as string,
  REDIRECT_URI: (window as any).ENV.REDIRECT_URI as string,
  AUTHORITY_URL: (window as any).ENV.AUTHORITY_URL as string,
  AUTHORITY_RESPONSE_TYPE: (window as any).ENV.AUTHORITY_RESPONSE_TYPE as string,
  POST_LOGOUT_REDIRECT_URI: (window as any).ENV.POST_LOGOUT_REDIRECT_URI as string,
  SILENT_RENEW_REDIRECT_URI: (window as any).ENV.SILENT_RENEW_REDIRECT_URI as string,
}