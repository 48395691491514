import axios from "axios";
import { ApiException } from "@clients/api-exception";
import { ProductConfig } from "@models/product-config/product-config.model";
import { ProductConfigResponse } from "@clients/product-config/dtos/product-config.response.dto";

const getProductConfig = async (productId: string): Promise<ProductConfig> => {
  try {
    const response = await axios.get(`/api/v1/products-config/${productId}`);
    return response.data;
  } catch (err) {
    const data = err.data[0];
    throw new ApiException(data?.message || 'Erro ao obter dados', err.status);
  }
};

const getProductsConfig = async (pageSize: number, pageNumber: number): Promise<ProductConfigResponse | ApiException> => {
  try {
    const response = await axios.get(`/api/v1/products-config?pageSize=${pageSize}&pageNumber=${pageNumber}`);
    return response.data;
  } catch (err) {
    const data = err.data[0];
    return new ApiException(data?.message || 'Erro ao obter dados', err.status);
  }
};

const deleteProductById = async (productId: string, userName: string): Promise<boolean | ApiException> => {
  try {
    const response = await axios.delete(`/api/v1/products-config/${productId}/?userName=${userName}.`);
    return response.data;
  } catch (err) {
    const data = err.data[0];
    return new ApiException(data?.message || 'Erro ao deletar dados', err.status);
  }
}

const saveProductConfig = async (productConfig: ProductConfig, username: string): Promise<void> => {
  try {
    const request = { username, ...productConfig };
    const response = await axios.post(`/api/v1/products-config`, request);
    return response.data;
  } catch (err) {
    const data = err.data[0];
    throw new ApiException(data?.message || 'Erro ao salvar configuração de produto.', err.status);
  }
};

export const ProductConfigClient = {
  getProductConfig,
  getProductsConfig,
  deleteProductById,
  saveProductConfig
}