import { Grid, TextField } from "@material-ui/core"
import useStyles from "@components/product-config/product-payment-condition/style";
import { ICreditCardFieldsComponentProps } from "@components/product-config/product-payment-condition/interfaces/credit-card-fields.components.interface";
import React from "react";
import {useForm} from "react-hook-form";
import { IRowPaymentConditionConfig } from "../interfaces/table.row.interface";

const fieldNames = {
  URL_EXPIRATION_MINUTES: 'urlExpirationMinutes',
  PAYMENT_EXPIRATION_MINUTES: 'paymentExpirationMinutes',
}

const fieldDefaultValues = {
  URL_EXPIRATION_MINUTES: 120,
  PAYMENT_EXPIRATION_MINUTES: 240,
}

export const CreditCardFieldsComponent: React.FC<ICreditCardFieldsComponentProps> = (properties) => {

  const classes = useStyles();
  const { formState: { errors } } = useForm();

  const onClearFields = () => {       
      properties.setValue(fieldNames.URL_EXPIRATION_MINUTES, fieldDefaultValues.URL_EXPIRATION_MINUTES);
      properties.setValue(fieldNames.PAYMENT_EXPIRATION_MINUTES, fieldDefaultValues.PAYMENT_EXPIRATION_MINUTES);
  }

  const onEditFields = (selectedItem: IRowPaymentConditionConfig) => {
      properties.setValue(fieldNames.URL_EXPIRATION_MINUTES, selectedItem.urlExpirationMinutes);
      properties.setValue(fieldNames.PAYMENT_EXPIRATION_MINUTES, selectedItem.paymentExpirationMinutes);
  }

  React.useEffect(() => {
    properties.onClearFields.current = onClearFields;
    properties.onEditFields.current = onEditFields;
  }, [])

  return (
    <>
      <Grid item md={3}
        className={classes.grid}>
        <TextField
          {...properties.registerForm(fieldNames.URL_EXPIRATION_MINUTES, {
              required: true,
              min: 1,
          })}
          className={classes.inputValue}
          inputProps={{ maxLength: 3 }}
          id="standard-basic"
          label="Expiração da URL em minutos"
          variant="standard"
          defaultValue={fieldDefaultValues.URL_EXPIRATION_MINUTES}
          type="number"
        />
          {errors?.urlExpirationMinutes?.type === "required" && <p className={classes.validationMessage}>Informe a expiração da url em minutos</p>}
          {errors?.urlExpirationMinutes?.type === "min" && <p className={classes.validationMessage}>Expiração deve ser maior que zero</p>}
      </Grid>

      <Grid item md={3}
        className={classes.grid}>
        <TextField
          {...properties.registerForm(fieldNames.PAYMENT_EXPIRATION_MINUTES, {
              required: true,
              min: 1,
          })}
          className={classes.inputValue}
          inputProps={{ maxLength: 3 }}
          id="standard-basic"
          label="Expiração do pagamento em minutos"
          variant="standard"
          defaultValue={fieldDefaultValues.PAYMENT_EXPIRATION_MINUTES}
          type="number"
        />
          {errors?.paymentExpirationMinutes?.type === "required" && <p className={classes.validationMessage}>Informe a expiração do pagamento em minutos</p>}
          {errors?.paymentExpirationMinutes?.type === "min" && <p className={classes.validationMessage}>Expiração deve ser maior que zero</p>}
      </Grid>
    </>
  )
}
