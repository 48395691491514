import { Routes, Navigate, Route } from 'react-router-dom'

import PrivateRoute from '@security/private-route'
import { 
  AccessCallback,
  Signin,
  PageSaveProductConfig,
  MenuPrincipal,
  PageProductConfig,
  PageUnauthorized } from '@pages/index'
import { routePaths } from '@shared/configs/constants/routes-path';
import Master from '@layouts/master';
  
const AppRoutes: React.FC = () => (

  <Routes>
    <Route path={routePaths.SIGNIN} element={<Signin />} />

    <Route
      path={routePaths.CALLBACK}
      element={<AccessCallback />}
    />

    <Route
      path={routePaths.UNAUTHORIZED}
      element={<PageUnauthorized />}
    />

    <Route
      path={routePaths.HOME}
      element={<PrivateRoute component={MenuPrincipal} layout={Master} />}
    />

    <Route
      path={routePaths.INICIO}
      element={<PrivateRoute component={MenuPrincipal} layout={Master} />}
    />

    <Route
      path={routePaths.PRODUCTS}
      element={<PrivateRoute component={PageProductConfig} layout={Master} />}
    />

    <Route
      path={routePaths.NEW_PRODUCT}
      element={<PrivateRoute component={PageSaveProductConfig} layout={Master} />}
    />

    <Route
      path={routePaths.EDIT_PRODUCT}
      element={<PrivateRoute component={PageSaveProductConfig} layout={Master} />}
    />

    <Route errorElement={<Navigate to={routePaths.NOT_FOUND_ERROR} />} />
  </Routes>
)

export default AppRoutes;
