export enum DocumentTypeEnum {
    Proposal = 1,
    Policy = 2,
    Endorsement = 3,
    Quote = 4,
    Boleto = 5
}

export const DocumentTypeLabel = new Map<number, string>([
    [DocumentTypeEnum.Proposal, 'Proposta'],
    [DocumentTypeEnum.Policy, 'Apólice'],
    [DocumentTypeEnum.Endorsement, 'Endosso'],
    [DocumentTypeEnum.Quote, 'Cotação'],
    [DocumentTypeEnum.Boleto, 'Boleto'],
])

export const DocumentTypeNumber = new Map<string, number>([
    ['Proposal', DocumentTypeEnum.Proposal],
    ['Policy', DocumentTypeEnum.Policy],
    ['Endorsement', DocumentTypeEnum.Endorsement],
    ['Quote', DocumentTypeEnum.Quote],
    ['Boleto', DocumentTypeEnum.Boleto]
])