export enum ModalityType {
    Undefined,
    Traditional,
    Monthly,
    ExemptIof
}

export const ModalityTypeLabel = new Map<number, string>([
    [ModalityType.Undefined, 'Indefinido'],
    [ModalityType.Traditional, 'Tradicional'],
    [ModalityType.Monthly, 'Mensalizado'],
    [ModalityType.ExemptIof, 'Isento de Iof'],
]);

export const ModalityTypeNumber = new Map<string, number>([
    ['Undefined', ModalityType.Undefined],
    ['Traditional', ModalityType.Traditional],
    ['Monthly', ModalityType.Monthly],
    ['ExemptIof', ModalityType.ExemptIof],
]);