import { ProductConfigClient, ProductConfigResponse } from "@clients/index";
import { ProductConfigTableStructure } from "@pages/product-config/dtos/table-structure.dto";

const getTableStructure = async (pageSize: number, pageNumber: number): Promise<ProductConfigTableStructure> => {
  const response = await ProductConfigClient.getProductsConfig(pageSize, pageNumber);
  const productConfigsResponse = response as unknown as ProductConfigResponse;
  const { products, totalRecords } = productConfigsResponse;
  return new ProductConfigTableStructure(products, totalRecords);
}

const deleteProductById = async (productId: string, userName: string): Promise<boolean> => {
  const response = await ProductConfigClient.deleteProductById(productId, userName);
  const deleteResponse = response as unknown as boolean;
  return deleteResponse;
}

export const PageProductConfigServices = {
  getTableStructure,
  deleteProductById
}