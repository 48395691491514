export enum InsuranceTypeEnum{
    Insurance = "1",
    AcceptedCoinsurance = "2"
}

export const InsuranceTypeLabel = new Map<string, string>([
    [InsuranceTypeEnum.Insurance, 'Seguro'],
    [InsuranceTypeEnum.AcceptedCoinsurance, 'Cosseguro aceito'],
]);

export const InsuranceTypeNumber = new Map<string, InsuranceTypeEnum>([
    ["Insurance", InsuranceTypeEnum.Insurance],
    ["AcceptedCoinsurance", InsuranceTypeEnum.AcceptedCoinsurance]
]);
