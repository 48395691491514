import React, {
    useEffect, useCallback, useState,
  } from 'react'
  import EventEmitter from 'events'
  import PropTypes from 'prop-types'
  import {
    head,
    isArray,
    isEmpty,
    isString,
  } from 'lodash'
  import Collapse from '@material-ui/core/Collapse'
  import Typography from '@material-ui/core/Typography'
  import MessageBox from '../message-box'
  import Alert from '@mui/material/Alert';
  
  const emitter = new EventEmitter()
  const DEFAULT_ID = 'global'
  
  const AlertProvider = (props) => {
    const { id } = props
    const [type, setType] = useState()
    const [text, setText] = useState()
    const [body, setBody] = useState()
    const [labelPrimary, setLabelPrimary] = useState('')
    const [maxWidth, setMaxWidth] = useState('')
    const [opened, setOpened] = useState()
    const [action, setAction] = useState()
    const [global, setGlobal] = useState(true)
    const [messages, setMessages] = useState()
    const [redirect, setRedirect] = useState(null)
    const [redirectClose, setRedirectClose] = useState(true)
  
    const cleanAlert = useCallback(() => setOpened(false), [])
  
    const addAlert = useCallback((data, alertType, redirectUrl, redirectAtClose = true) => {
      setAction({ run: () => { } })
      setType(alertType)
      setMessages()
      setText()
      setBody()
  
      if (isString(data)) {
        setText(data)
  
      } else if (isArray(data) && data.length === 1) {
        const value = head(data)
        setText(value.message)
  
      } else if (isArray(data)) {
        setMessages(data)
  
      } else {
        const {
          title,
          message,
          lblPrimary,
          width,
          action: handle = () => { },
        } = data
  
        setAction({ run: handle })
        if (title) {
          setText(title)
        } else if (message) {
          setText(message)
        }
  
        if (title && message) {
          setBody(message)
        }
  
        if (title && message && lblPrimary) {
          setBody(message)
          if (width) {
            setMaxWidth(width)
          }
          setLabelPrimary(lblPrimary)
        }
      }
      setRedirect(() => redirectUrl)
      setRedirectClose(redirectAtClose)
      setOpened(true)
    }, [])
  
    useEffect(() => setGlobal(id === DEFAULT_ID), [id])
  
    useEffect(() => {
      const addDanger = (data, redirectUrl, closeRedirect) => addAlert(data, 'error', redirectUrl, closeRedirect)
      emitter.on(`${id}.alert.danger`, addDanger)
  
      const addSuccess = (data, redirectUrl, closeRedirect) => addAlert(data, 'success', redirectUrl, closeRedirect)
      emitter.on(`${id}.alert.success`, addSuccess)
  
      const addWarning = (data, redirectUrl, closeRedirect) => addAlert(data, 'warning', redirectUrl, closeRedirect)
      emitter.on(`${id}.alert.warning`, addWarning)
  
      const cleanMsg = () => cleanAlert()
      emitter.on(`${id}.alert.clean`, cleanMsg)
  
      return () => {
        emitter.removeListener(`${id}.alert.clean`, cleanMsg)
        emitter.removeListener(`${id}.alert.danger`, addDanger)
        emitter.removeListener(`${id}.alert.success`, addSuccess)
        emitter.removeListener(`${id}.alert.warning`, addWarning)
      }
    }, [id, addAlert, cleanAlert])
  
    const handleClose = () => {
      setOpened(false)
      setLabelPrimary('')
      action.run()
      if (typeof redirect === 'function' && redirectClose) {
        redirect()
      }
    }
  
    const handlePrimary = () => {
      setOpened(false)
      setLabelPrimary('')
      action.run()
      if (typeof redirect === 'function') {
        redirect()
      }
    }
  
    const messagesRender = () => (
      <Typography variant="body2" component="div">
        <ul>
          {messages.filter((item) => !isEmpty(item.message)).map(
            (item, index) => (
              <li key={index}>
                <span dangerouslySetInnerHTML={{ __html: item.message }} />
              </li>
            ),
          )}
        </ul>
      </Typography>
    )
  
    return (
      <>
        {global && (
          <MessageBox
            type={type}
            text={text}
            opened={opened}
            labelPrimary={labelPrimary || 'Voltar'}
            handleClose={handleClose}
            handlePrimary={handlePrimary}
            maxWidth={maxWidth || 'xs'}
          >
            {body}
            {messages && messagesRender()}
          </MessageBox>
        )}
  
        {!global && (
          <Collapse in={opened}>
            <Alert
              severity={type}
              closeText="Fechar"
              onClose={handleClose}
            >
              {messages && messagesRender()}
              {!isEmpty(text) && (
                <span dangerouslySetInnerHTML={{ __html: text }} />)}
            </Alert>
          </Collapse>
        )}
      </>
    )
  }
  
  export const useAlert = (id = DEFAULT_ID) => {
    const addMsg = useCallback((data, event, redirect, closeRedirect) => {
      if (isEmpty(data)) {
        return
      }
      emitter.emit(event, data, redirect, closeRedirect)
    }, [])
  
    const addMsgSuccess = useCallback((data, redirect, closeAtRedirect) => {
      addMsg(data, `${id}.alert.success`, redirect, closeAtRedirect)
    }, [id, addMsg])
  
    const addMsgWarning = useCallback((data, redirect, closeAtRedirect) => {
      addMsg(data, `${id}.alert.warning`, redirect, closeAtRedirect)
    }, [id, addMsg])
  
    const addMsgDanger = useCallback((data, redirect, closeAtRedirect) => {
      addMsg(data, `${id}.alert.danger`, redirect, closeAtRedirect)
    }, [id, addMsg])
  
    const cleanMsg = useCallback(() => {
      emitter.emit(`${id}.alert.clean`)
    }, [id])
  
    return {
      cleanMsg,
      addMsgDanger,
      addMsgWarning,
      addMsgSuccess,
    }
  }
  
  AlertProvider.propTypes = {
    id: PropTypes.string,
  }
  
  AlertProvider.defaultProps = {
    id: DEFAULT_ID,
  }
  
  export default AlertProvider
  