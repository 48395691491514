export enum RefundIof{
    DoesNotRefund,
    Refund,  
}

export const RefundIofLabel = new Map<number, string>([
    [RefundIof.Refund, 'Restitui'],
    [RefundIof.DoesNotRefund, 'Não restitui'],
]);

