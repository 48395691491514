export enum ChannelType {
    PortalProduto = 1,
    PortalCliente = 2,
    PortalCorretor = 3,
    EmissaoLote = 4,
    VendaDireta = 5,
    CentralVendas = 6,
    PortalVoce = 7,
    PortalApi = 8,
    AssistenteVirtual = 9,
    CentralCliente = 10,
    PottencialEmpresas = 11
}

export const ChannelTypeLabel = new Map<number, string>([
    [ChannelType.PortalProduto, 'Portal produto'],
    [ChannelType.PortalCliente, 'Portal cliente'],
    [ChannelType.PortalCorretor, 'Portal corretor'],
    [ChannelType.EmissaoLote, 'Emissao lote'],
    [ChannelType.VendaDireta, 'Venda direta'],
    [ChannelType.CentralVendas, 'Central vendas'],
    [ChannelType.PortalVoce, 'Portal voce'],
    [ChannelType.PortalApi, 'Portal api'],
    [ChannelType.AssistenteVirtual, 'Assistente virtual'],
    [ChannelType.CentralCliente, 'Central cliente'],
    [ChannelType.PottencialEmpresas, 'Pottencial empresas'],
]);

export const ChannelTypeNumber = new Map<string, number>([
    ['PortalProduto', ChannelType.PortalProduto],
    ['PortalCliente', ChannelType.PortalCliente],
    ['PortalCorretor', ChannelType.PortalCorretor],
    ['EmissaoLote', ChannelType.EmissaoLote],
    ['VendaDireta', ChannelType.VendaDireta],
    ['CentralVendas', ChannelType.CentralVendas],
    ['PortalVoce', ChannelType.PortalVoce],
    ['PortalApi', ChannelType.PortalApi],
    ['AssistenteVirtual', ChannelType.AssistenteVirtual],
    ['CentralCliente', ChannelType.CentralCliente],
    ['PottencialEmpresas', ChannelType.PottencialEmpresas],
]);