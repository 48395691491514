import { Divider, Grid, Paper, Button, Typography } from '@material-ui/core'
import Box from '@mui/material/Box'
import useStyles from '@pages/product-config/style'
import { AddCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PageProductConfigServices } from './services'
import MessageBox from '@components/message-box'
import { useAlert } from '@components/alert-box'
import useSecurity from '@security/user-security'
import { ProductConfigTableStructure } from '@pages/product-config/dtos/table-structure.dto'
import { IRowTableProductConfig } from '@pages/product-config/interfaces/table.row.interface'
import { DynamicTable, Pagination } from '@components/index'
import { ApiException } from '@clients/index'
import { useActiveHeader, useSubHeader } from '@pottencial-tangerine/ui'

const data: ProductConfigTableStructure = {
  columns: [],
  rows: [],
  totalRecords: 0,
}

export const PageProductConfig = () => {
  const classes = useStyles()
  const { addMsgSuccess, addMsgDanger } = useAlert()
  const getUsername = useSecurity()
  const navigate = useNavigate()
  const [tableDataState, setTableDataState] = useState(data)
  const [pageState, setPageState] = useState(1)
  const [openCancel, setOpenCancel] = useState(false)
  const [productToDelete, setProductToDelete] = useState('')
  useActiveHeader("products");
  useSubHeader({
    title: 'Produtos',
    breadcrumb: {
      home: () => navigate('/home'),
      options: [
        { label: 'Produtos', href: () => navigate('/products') }
      ]
    }
  });

  const pageSizeData = 10;

  function actionEdit(props: IRowTableProductConfig) {
    navigate(`/products/edit-product/${props.id}`)
  }

  function actionAddNewProduct() {
    navigate(`/products/new-product`)
  }

  function actionDelete(props: IRowTableProductConfig) {
    setProductToDelete(props.id)
    setOpenCancel(true)
  }

  const handleDeleteProduct = async (productToDelete: string) => {
    setOpenCancel(false)
    const userName = getUsername.getName();
    PageProductConfigServices.deleteProductById(productToDelete, userName)
      .then((result: boolean | ApiException) => {
        if (result instanceof ApiException) {
          addMsgDanger(result.message)
        } else {
          addMsgSuccess(`Produto ${productToDelete} excluído com sucesso!`, '/products', true)
          getProductsConfigs(pageSizeData, pageState);
        }
      })
  }

  const updatePage = (value: number) => {
    setPageState(value + 1)
  }

  const getProductsConfigs = async (pageSize: number, pageNumber: number) => {
    // todo botar try catch na chamada
    PageProductConfigServices.getTableStructure(pageSize, pageNumber)
      .then((result: ProductConfigTableStructure | ApiException) => {
        if (result instanceof ApiException) {
          setTableDataState(data)
          addMsgDanger(result.message)
        } else {
          setTableDataState(result)
        }
      })

  }

  useEffect(() => {
    getProductsConfigs(pageSizeData, pageState);
  }, [pageState]);

  return (
    <Box component={Paper} p={4} my={10}>
      <Grid container justifyContent="space-between">

        <Typography variant="subtitle1" component="h2">
          <Box fontWeight="bold">Lista de produtos</Box>
          <Divider className={classes.divider} />
        </Typography>

        <Button
          color="primary"
          title="Novo produto"
          variant="outlined"
          onClick={() => actionAddNewProduct()}
          startIcon={<AddCircle />}
        >
          Novo produto
        </Button>

      </Grid>

      <br />
      {tableDataState.rows &&
        (<>
          <DynamicTable data={tableDataState} actionEditClick={(props) => actionEdit(props)} actionDeleteClick={(props) => actionDelete(props)} />
          <Box>
            <Pagination
              totalResults={tableDataState.totalRecords}
              changePaginator={updatePage}
            />
          </Box>
        </>
        )
      }
      <MessageBox
        opened={openCancel}
        handleClose={() => setOpenCancel(false)}
        handlePrimary={() => handleDeleteProduct(productToDelete)}
        handleSecondary={() => setOpenCancel(false)}
        type='warning'
        title="Excluir Produto"
        text="Deseja Excluir? Ao clicar em Confirmar todas as informações deste produto serão perdidas!"
      />
    </Box>
  )
}
