export default function arraysEqual(array1: Array<Object>, array2: Array<Object>): Boolean {

  if (array1 === array2) return true;
  if (array1 == null || array2 == null) return false;
  if (array1.length !== array2.length) return false;

  const cloneArray1 = [...array1].sort();
  const cloneArray2 = [...array2].sort();

  for (var i = 0; i < cloneArray1.length; ++i) {
    if (cloneArray1[i] !== cloneArray2[i])
      return false;
  }

  return true;
}