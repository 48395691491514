import { PaymentTypeEnum } from "@enums/index";
import { InsuranceTypeEnum } from "@components/product-config/product-payment-condition/dtos/insurance-type.enum";
import { BoletoBankAgreement } from "@models/product-config/payment-condition/boleto-payment-condition/boleto-bank-agreement.model";

export default class PaymentCondition {
  
  id: string;
  maxInstallments: number;
  delayPercentage: number;
  interestPercentage: number;
  paymentType: PaymentTypeEnum;
  isDefaultPaymentType: boolean;
  limitDaysAfterExpiration: number;
  insuranceType: InsuranceTypeEnum;
  enableOnPortal: boolean;

  // CREDIT CARD
  urlExpirationMinutes: string;
  paymentExpirationMinutes: string;

  bankAgreements: Array<BoletoBankAgreement>;

  constructor(){
    this.bankAgreements = new Array<BoletoBankAgreement>();
  }
}
