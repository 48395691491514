import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '98px',
    left: '0px',
    top: '80px',
    background: '#FD742B'
  },
  title:{
    color: 'white',
    textAlign: 'left',
    font: '2rem Poppins',
    fontWeight: 'bold',
    marginRight: '8px',
    paddingLeft: '30px'
  }
}))