import { Box, Button, Card, Divider, Grid, InputLabel, NativeSelect, Typography } from '@material-ui/core';
import useStyles from '@components/product-config/channel-config/style';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { DynamicTable } from '@components/dynamic-table/dynamic-table';
import { IRowTableChannelConfig } from '@components/product-config/channel-config/interfaces/table.row.interface';
import { ChannelConfigTableStructure } from '@components/product-config/channel-config/dtos/table-structure.dto';
import { AddCircle } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import ChannelConfigDto from '@components/product-config/channel-config/dtos/channel-config.dto';
import { ChannelType, ChannelTypeLabel } from 'enums';
import React from 'react';
import icoWarning from '@assets/img/ico-alert-warning.svg';
import MessageBox from '@components/message-box';
import ChannelConfig from '@models/product-config/channel-config.model';
import IChannelConfigComponentProps from '@components/product-config/channel-config/interfaces/channel-config.component.props.interface';

const emptyTableState = new ChannelConfigTableStructure(new Array<ChannelConfigDto>(), 0);

const channelTypes = [
  {
    text: ChannelTypeLabel.get(ChannelType.PortalProduto),
    value: ChannelType.PortalProduto
  },
  {
    text: ChannelTypeLabel.get(ChannelType.PortalCliente),
    value: ChannelType.PortalCliente
  },
  {
    text: ChannelTypeLabel.get(ChannelType.PortalCorretor),
    value: ChannelType.PortalCorretor
  },
  {
    text: ChannelTypeLabel.get(ChannelType.EmissaoLote),
    value: ChannelType.EmissaoLote
  },
  {
    text: ChannelTypeLabel.get(ChannelType.VendaDireta),
    value: ChannelType.VendaDireta
  },
  {
    text: ChannelTypeLabel.get(ChannelType.CentralVendas),
    value: ChannelType.CentralVendas
  },
  {
    text: ChannelTypeLabel.get(ChannelType.PortalVoce),
    value: ChannelType.PortalVoce
  },
  {
    text: ChannelTypeLabel.get(ChannelType.PortalApi),
    value: ChannelType.PortalApi
  },
  {
    text: ChannelTypeLabel.get(ChannelType.AssistenteVirtual),
    value: ChannelType.AssistenteVirtual
  },
  {
    text: ChannelTypeLabel.get(ChannelType.CentralCliente),
    value: ChannelType.CentralCliente
  },
  {
    text: ChannelTypeLabel.get(ChannelType.PottencialEmpresas),
    value: ChannelType.PottencialEmpresas
  },
]

const fieldNames = {
  ID: 'id',
  CHANNEL_TYPE: 'channelType',
  CREDIT_CARD_URL_CALLBACK: 'creditCardUrlCallback',
  CREDIT_CARD_URL_CALLBACK_AFTER_SALE: 'creditCardUrlCallbackAfterSale'
}

const fieldDefaultValues = {
  ID: null,
  CHANNEL_TYPE: ChannelType.PortalProduto,
  CREDIT_CARD_URL_CALLBACK: '',
  CREDIT_CARD_URL_CALLBACK_AFTER_SALE: ''
}

export const ChannelConfigComponent: React.FC<IChannelConfigComponentProps> = ({ productConfig }) => {
  const [tableDataState, setTableDataState] = useState(emptyTableState);
  const [channelConfigDuplicatedMessageOpenedState, setChannelConfigDuplicatedMessageOpenedState] = useState(false)
  const [openDeleteConfirmationState, setOpenDeleteConfirmationState] = useState(false)
  const [channelConfigToDelete, setIOFConfigToDelete] = useState(null as IRowTableChannelConfig)
  const { handleSubmit, register, setValue, formState: { errors } } = useForm();
  const classes = useStyles();

  const actionSelectChannelConfig = (selectedItem: IRowTableChannelConfig) => {
    setValue(fieldNames.CHANNEL_TYPE, selectedItem.channelType);
    setValue(fieldNames.CREDIT_CARD_URL_CALLBACK, selectedItem.creditCardUrlCallback);
    setValue(fieldNames.CREDIT_CARD_URL_CALLBACK_AFTER_SALE, selectedItem.creditCardUrlCallbackAfterSale);
    setValue(fieldNames.ID, selectedItem.id);
  }

  const configurationAlreadyExists = (list: Array<IRowTableChannelConfig>, object: IRowTableChannelConfig) => {
    var listItems = list.filter(o => Number(o.channelType) === Number(object.channelType));

    if (listItems.length === 0) {
      return false;
    }

    if ((listItems.length === 1) && (listItems[0].id === object.id)) {
      return false;
    }

    return true;
  }

  const clearChannelFields = () => {
    setValue(fieldNames.CHANNEL_TYPE, fieldDefaultValues.CHANNEL_TYPE);
    setValue(fieldNames.CREDIT_CARD_URL_CALLBACK, fieldDefaultValues.CREDIT_CARD_URL_CALLBACK);
    setValue(fieldNames.CREDIT_CARD_URL_CALLBACK_AFTER_SALE, fieldDefaultValues.CREDIT_CARD_URL_CALLBACK_AFTER_SALE);
    setValue(fieldNames.ID, fieldDefaultValues.ID);
  }

  const actionAddNewChannelConfig = (data: ChannelConfigDto) => {
    if (!data.id) {
      const newId = (tableDataState.rows.length + 1).toString();
      data.id = newId;
    }

    if (configurationAlreadyExists(tableDataState.rows, data)) {
      setChannelConfigDuplicatedMessageOpenedState(true);
      return;
    }

    let channelConfigs = tableDataState.rows as unknown as ChannelConfigDto[];

    const previousChannelConfig = channelConfigs.find(i => i.id === data.id);

    if (!previousChannelConfig) {
      channelConfigs.push(data);
    } else {
      Object.assign(previousChannelConfig, data);
    }

    const newTableDataState = new ChannelConfigTableStructure(channelConfigs, channelConfigs.length);

    setChannelConfigsOnProductConfig(channelConfigs);

    setTableDataState(newTableDataState);

    clearChannelFields();
  }

  const actionDelete = (selectedItem: IRowTableChannelConfig) => {
    setIOFConfigToDelete(selectedItem);
    setOpenDeleteConfirmationState(true);
  }

  const setChannelConfigsOnProductConfig = (channelConfigs: Array<ChannelConfigDto>) => {
    productConfig.channelConfigs = new Array<ChannelConfig>();

    channelConfigs.forEach((channelConfigDto) => {
      const newChannelConfig = {
        type: channelConfigDto.channelType,
        creditCardUrlCallback: channelConfigDto.creditCardUrlCallback,
        creditCardUrlCallbackAfterSale: channelConfigDto.creditCardUrlCallbackAfterSale
      } as ChannelConfig;
      productConfig.channelConfigs.push(newChannelConfig);
    })
  }

  const handleDeleteChannelConfig = async (configurationToDelete: IRowTableChannelConfig) => {
    setOpenDeleteConfirmationState(false);

    let channelConfigs = tableDataState.rows as unknown as ChannelConfigDto[];

    channelConfigs = channelConfigs.filter(item => (item.id !== configurationToDelete.id));

    const newTableDataState = new ChannelConfigTableStructure(channelConfigs, channelConfigs.length);

    setChannelConfigsOnProductConfig(channelConfigs);

    setTableDataState(newTableDataState);
  }

  const completeTable = () => {

    if (!productConfig?.channelConfigs.length) {
      setTableDataState(new ChannelConfigTableStructure(new Array<ChannelConfigDto>(), 0))
      return;
    }

    productConfig.channelConfigs.forEach((channelConfig, index) => {
      const id = (index + 1).toString();
      const channelConfigDto = new ChannelConfigDto(id, channelConfig);
      actionAddNewChannelConfig(channelConfigDto);
    });
  }

  useEffect(() => {

    completeTable();

  }, [productConfig])

  return (
    <Box className={classes.root}>
      <Card>
        <form>
          <Grid container className={classes.imputValues}>
            <Grid container justifyContent="space-between" className={classes.imputValuesHeader}>
              <Typography variant="subtitle1" component="h2">
                <Box fontWeight="bold">Canais de venda</Box>
                <Divider className={classes.divider} />
              </Typography>
              <Button
                className={classes.imputValuesCommand}
                color="primary"
                title="Gravar configuração"
                variant="outlined"
                onClick={handleSubmit(actionAddNewChannelConfig)}
                startIcon={<AddCircle />}
              >
                Gravar configuração
              </Button>
            </Grid>

            <Grid item md={4} className={classes.grid}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Tipo de canal
              </InputLabel>
              <NativeSelect
                {...register(fieldNames.CHANNEL_TYPE)}
                className={classes.selector}
                defaultValue={fieldDefaultValues.CHANNEL_TYPE}
              >
                {channelTypes.map(item => {
                  return (<option key={item.value} value={item.value}>{item.text}</option>);
                })}
              </NativeSelect>
            </Grid>
            <Grid item md={4} className={classes.grid}>
              <TextField
                {...register(fieldNames.CREDIT_CARD_URL_CALLBACK, { required: true })}
                className={classes.imputValue}
                id="standard-basic"
                label="Url de callback cartão de crédito"
                variant="standard"
                type="text" />
              {errors?.creditCardUrlCallback?.type === "required" && <p className={classes.validationMessage}>Informe a url de callback do cartão de crédito</p>}
            </Grid>
            <Grid item md={4} className={classes.grid}>
              <TextField
                {...register(fieldNames.CREDIT_CARD_URL_CALLBACK_AFTER_SALE)}
                className={classes.imputValue}
                id="standard-basic"
                label="Url de callback cartão de crédito pós venda"
                variant="standard"
                type="text" />
            </Grid>
          </Grid>
          <DynamicTable
            data={tableDataState}
            actionEditClick={(props) => actionSelectChannelConfig(props)}
            actionDeleteClick={(props) => actionDelete(props)}
          />
        </form>

        <MessageBox
          opened={channelConfigDuplicatedMessageOpenedState}
          thumb={icoWarning}
          handleClose={() => {
            setChannelConfigDuplicatedMessageOpenedState(false)
          }}
          handleSecondary={() => {
            setChannelConfigDuplicatedMessageOpenedState(false)
          }}
          labelSecondary="Fechar"
          title="Não foi possível incluir o canal de venda"
          text="Canal de venda já cadastrado para tipo de canal"
        />

        <MessageBox
          opened={openDeleteConfirmationState}
          handleClose={() => setOpenDeleteConfirmationState(false)}
          handlePrimary={() => handleDeleteChannelConfig(channelConfigToDelete)}
          handleSecondary={() => setOpenDeleteConfirmationState(false)}
          type='warning'
          title="Excluir configuração canal de venda"
          text="Deseja Excluir? Ao clicar em Confirmar todas as informações desta configuração serão perdidas!"
        />
      </Card>
    </Box>
  )
}