import { Callback } from 'react-oidc';
import { useNavigate } from 'react-router-dom';
import useSecurity from '@security/user-security';
import { useSecurityAction } from '@storages/ducks/security';
import SplashScreen from '@components/splash-screen';

export const AccessCallback = () => {
  const navigate = useNavigate()
  const { userManager } = useSecurity()
  const { setCredential } = useSecurityAction()

  return (
    <>
      <Callback
        userManager={userManager}
        onSuccess={(credential) => {
          setCredential(credential)

          navigate('/')
        }} />

      <SplashScreen />
    </>
  )
}