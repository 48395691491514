import { Wallet } from "./wallet.model";

export class BoletoBankAgreement {
  enable: boolean;
  bankName: string;
  bankNumber: string;
  providerName: string;
  companyName: string;
  companyDocument: string;
  wallets: Array<Wallet>;
 
  constructor(){
    this.wallets = new Array<Wallet>();
  }
}