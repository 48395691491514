import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    paddingBottom:"1%"
  },
  divider: {
    width: 60,
    background: '#F26522',
    padding: 0.5,
    height: 2,
  },
  inputValues: {
    width: '100%',
    marginBottom: '25px'   
  },
  inputValuesHeader: {
    marginBottom: '20px',
    paddingTop:'1%',
    paddingLeft: '2%',
    paddingRight: '2%',
  },
  inputValuesCommand:{
    paddingRight: '2%',
    paddingLeft: '2%',
  },
  selector: {
    width:"100%",
    paddingLeft:"2%",
    paddingRight:"2%",
    paddingTop:"1%"
  },
  grid: {
    width: '100%',
    paddingTop:'1%',
    paddingLeft: '2%',
    paddingRight: '2%',
  },
  inputValue: {
    width: '100%',
    paddingTop:'1%',
    paddingLeft: '2%',
    paddingRight: '2%',
  },
  validationMessage: {
    color: 'red',
    fontSize: '12px',
    height: '8px',
    marginBlockStart: '0',
    marginBlockEnd: '0',
  },
}))
