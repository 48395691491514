import { Box, Button, Divider, Grid, InputLabel, NativeSelect, TextField, Typography } from "@material-ui/core"
import useStyles from "@components/product-config/product-payment-condition/style";
import { IBoletoFieldsComponentProps } from "@components/product-config/product-payment-condition/interfaces/boleto-fields.components.interface"
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EnumType } from "typescript";
import getEnumDescriptions from "@shared/utils/functions/get-enum-itens-as_array.function";
import { BankEnum, BankNameLabel, BankNumberLabel, DocumentTypeEnum, DocumentTypeLabel, PaymentProcessingTimeType, PaymentProcessingTimeTypeLabel } from "enums";
import { IRowPaymentConditionConfig } from "@components/product-config/product-payment-condition/interfaces/table.row.interface";
import { BoletoBankAgreement } from "../../../../models/product-config/payment-condition/boleto-payment-condition/boleto-bank-agreement.model";
import PaymentConditionConfigDto from "../dtos/payment-condition-config.dto";
import MessageBox from "@components/message-box/message.box";
import { IRowWallet } from "../interfaces/wallet.table.row.interface";
import WalletDto from "../dtos/wallet.dto";
import { WalletTableStructure } from "../dtos/wallet-table-structure.dto";
import { DynamicTable } from "@components/dynamic-table/dynamic-table";
import { Wallet } from "@models/product-config/payment-condition/boleto-payment-condition/wallet.model";
import { Add, AddCircle } from "@mui/icons-material";

const emptyTableState = new WalletTableStructure(new Array<WalletDto>(), 0);

const documentTypes = [
    {
        text: DocumentTypeLabel.get(DocumentTypeEnum.Boleto),
        value: DocumentTypeEnum.Boleto
    },
    {
        text: DocumentTypeLabel.get(DocumentTypeEnum.Endorsement),
        value: DocumentTypeEnum.Endorsement
    },
    {
        text: DocumentTypeLabel.get(DocumentTypeEnum.Policy),
        value: DocumentTypeEnum.Policy
    },
    {
        text: DocumentTypeLabel.get(DocumentTypeEnum.Proposal),
        value: DocumentTypeEnum.Proposal
    },
    {
        text: DocumentTypeLabel.get(DocumentTypeEnum.Quote),
        value: DocumentTypeEnum.Quote
    },
]

const paymentProcessingTimeTypes = [
    {
        text: PaymentProcessingTimeTypeLabel.get(PaymentProcessingTimeType.ProposalAcceptance),
        value: PaymentProcessingTimeType.ProposalAcceptance
    },
    {
        text: PaymentProcessingTimeTypeLabel.get(PaymentProcessingTimeType.PolicyIssuance),
        value: PaymentProcessingTimeType.PolicyIssuance
    },
]

const fieldNames = {
    ID: 'id',
    BANK_NAME: 'bankName',
    BANK_NUMBER: 'bankNumber',
    PROVIDER_NAME: 'providerName',
    COMPANY_NUMBER: "companyDocument",
    COMPANY_NAME: "companyName",
    WALLETS: "wallets",
    ENABLE: 'enable',
    WALLET_ID: 'id',
    WALLET_CODE: "walletCode",
    WALLET_NUMBER: "walletNumber",
    AGENCY_NUMBER: "agencyNumber",
    DOCUMENT_TYPE: "documentType",
    AGREEMENT_NUMBER: "agreementNumber",
    ACCOUNT_NUMBER: "accountNumber",
    ACCOUNT_NUMBER_DIGIT: "accountNumberDigit",
    PAYMENT_PROCESSING_TIME_TYPE: "paymentProcessingTimeType",
    LIMIT_DAYS_AFTER_EXPIRATION: "limitDaysAfterExpiration"
}

const fieldDefaultValues = {
    BANK_NAME: BankEnum.Itau,
    BANK_NUMBER: BankNumberLabel.get(BankEnum.Itau),
    PROVIDER_NAME: "",
    COMPANY_NUMBER: "11699534000174",
    COMPANY_NAME: "POTTENCIAL SEGURADORA SA",
    ENABLE: true,
    WALLET_ID: null,
    WALLET_CODE: "",
    WALLET_NUMBER: "",
    AGENCY_NUMBER: "",
    DOCUMENT_TYPE: DocumentTypeEnum.Boleto,
    AGREEMENT_NUMBER: "",
    ACCOUNT_NUMBER: "",
    ACCOUNT_NUMBER_DIGIT: "",
    PAYMENT_PROCESSING_TIME_TYPE: PaymentProcessingTimeType.Undefined,
    LIMIT_DAYS_AFTER_EXPIRATION: "",
}

export const BoletoFieldsComponent: React.FC<IBoletoFieldsComponentProps> = (properties) => {

    const { handleSubmit, register, setValue, formState: { errors } } = useForm();
    const classes = useStyles();
    const [tableDataState, setTableDataState] = useState(emptyTableState);
    const [walletDuplicatedMessageOpenedState, setWalletDuplicatedMessageOpenedState] = useState(false);
    const [showWalletFieldsState, setShowWalletFieldsState] = useState(false);
    const [showAddNewWalletButtonState, setShowAddNewWalletButtonState] = useState(false);
    const [openDeleteConfirmationState, setOpenDeleteConfirmationState] = useState(false)
    const [walletToDelete, setWalletToDelete] = useState(null as IRowWallet)
    let currentWalletsVariable = [];

    React.useEffect(() => {
        properties.onClearFields.current = onClearFields;
        properties.onEditFields.current = onEditFields;
        properties.createBankAgreement.current = createBankAgreement;

        if (tableDataState.rows.length === 0) {
            const enableBankAgreement = getEnableBankAgreement();
            if (enableBankAgreement)
                completeTable(enableBankAgreement.wallets)
        }
    }, [properties.productConfig]);


    const walletAlreadyExists = (list: Array<IRowWallet>, object: IRowWallet) => {
        var listItems = list.filter(w => Number(w.paymentProcessingTimeType) === Number(object.paymentProcessingTimeType));

        if (listItems.length === 0) {
            return false;
        }

        if ((listItems.length === 1) && (listItems[0].id === object.id)) {
            return false;
        }

        return true;
    }

    const addNewWallet = (data: WalletDto) => {
        setShowWalletFieldsState(false);
        setShowAddNewWalletButtonState(true);

        let filteredArray = currentWalletsVariable;

        if (tableDataState.rows.length > 0) {
            const arrayCurrentWalletsVariable = currentWalletsVariable;
            const arraytableDataState = tableDataState.rows;
            filteredArray = arrayCurrentWalletsVariable.filter(currentWallet => arraytableDataState.some(tableData => tableData.id === currentWallet.id));

            if (!currentWalletsVariable.length)
                filteredArray = arraytableDataState;
        }

        if (!data.id) {
            data.id = (filteredArray.length + 1).toString();
        }

        if (walletAlreadyExists(filteredArray, data)) {
            setWalletDuplicatedMessageOpenedState(true);
            return;
        }

        const previousWallets = filteredArray.find(i => i.id === data.id);

        if (!previousWallets) {
            filteredArray.push(data)
        } else {
            Object.assign(previousWallets, data);
        }

        updateProductConfig(filteredArray);

        const newTableDataState = new WalletTableStructure(filteredArray, filteredArray.length);

        setTableDataState(newTableDataState);

        onClearWallet();
    }

    const completeTable = (wallets: Array<Wallet>) => {
        currentWalletsVariable = wallets?.map((wallet, walletIndex) => new WalletDto((walletIndex + 1).toString(), wallet));

        currentWalletsVariable?.forEach((wallet) => {
            addNewWallet(wallet);
        })
    }

    const onClearFields = (cleanWallets: boolean = false) => {
        properties.setValue(fieldNames.BANK_NAME, fieldDefaultValues.BANK_NAME);
        properties.setValue(fieldNames.BANK_NUMBER, fieldDefaultValues.BANK_NUMBER);
        properties.setValue(fieldNames.PROVIDER_NAME, fieldDefaultValues.PROVIDER_NAME);
        properties.setValue(fieldNames.COMPANY_NUMBER, fieldDefaultValues.COMPANY_NUMBER);
        properties.setValue(fieldNames.COMPANY_NAME, fieldDefaultValues.COMPANY_NAME);
        properties.setValue(fieldNames.ENABLE, fieldDefaultValues.ENABLE);
        setValue(fieldNames.WALLETS, []);

        if (cleanWallets)
            setTableDataState(new WalletTableStructure(new Array<WalletDto>(), 0));
    }

    const onClearWallet = () =>{
        setValue(fieldNames.WALLET_ID, fieldDefaultValues.WALLET_ID);
        setValue(fieldNames.WALLET_CODE, fieldDefaultValues.WALLET_CODE);
        setValue(fieldNames.WALLET_NUMBER, fieldDefaultValues.WALLET_NUMBER);
        setValue(fieldNames.AGENCY_NUMBER, fieldDefaultValues.AGENCY_NUMBER);
        setValue(fieldNames.DOCUMENT_TYPE, fieldDefaultValues.DOCUMENT_TYPE);
        setValue(fieldNames.AGREEMENT_NUMBER, fieldDefaultValues.AGREEMENT_NUMBER);
        setValue(fieldNames.ACCOUNT_NUMBER, fieldDefaultValues.ACCOUNT_NUMBER);
        setValue(fieldNames.ACCOUNT_NUMBER_DIGIT, fieldDefaultValues.ACCOUNT_NUMBER_DIGIT);
        setValue(fieldNames.PAYMENT_PROCESSING_TIME_TYPE, fieldDefaultValues.PAYMENT_PROCESSING_TIME_TYPE);
        setValue(fieldNames.LIMIT_DAYS_AFTER_EXPIRATION, fieldDefaultValues.LIMIT_DAYS_AFTER_EXPIRATION);
    }

    const onEditFields = (selectedItem: IRowPaymentConditionConfig) => {
        properties.setValue(fieldNames.BANK_NAME, selectedItem.bankName);
        properties.setValue(fieldNames.BANK_NUMBER, selectedItem.bankNumber);
        properties.setValue(fieldNames.PROVIDER_NAME, selectedItem.providerName);
        properties.setValue(fieldNames.COMPANY_NUMBER, selectedItem.companyDocument);
        properties.setValue(fieldNames.COMPANY_NAME, selectedItem.companyName);
        properties.setValue(fieldNames.ENABLE, true);
        properties.setValue(fieldNames.ID, selectedItem.id);
        properties.setValue(fieldNames.WALLETS, selectedItem.wallets);

        completeTable(selectedItem.wallets)
        setShowAddNewWalletButtonState(true);
    }

    const actionSelectWallet = (selectedItem: IRowWallet) => {
        setShowWalletFieldsState(true);
        setShowAddNewWalletButtonState(false);
        setValue(fieldNames.ID, selectedItem.id);
        setValue(fieldNames.WALLET_CODE, selectedItem.walletCode);
        setValue(fieldNames.WALLET_NUMBER, selectedItem.walletNumber);
        setValue(fieldNames.AGENCY_NUMBER, selectedItem.agencyNumber);
        setValue(fieldNames.DOCUMENT_TYPE, selectedItem.documentType);
        setValue(fieldNames.AGREEMENT_NUMBER, selectedItem.agreementNumber);
        setValue(fieldNames.ACCOUNT_NUMBER, selectedItem.accountNumber);
        setValue(fieldNames.ACCOUNT_NUMBER_DIGIT, selectedItem.accountNumberDigit);
        setValue(fieldNames.PAYMENT_PROCESSING_TIME_TYPE, selectedItem.paymentProcessingTimeType);
        setValue(fieldNames.LIMIT_DAYS_AFTER_EXPIRATION, selectedItem.limitDaysAfterExpiration);
    }

    const actionDelete = (selectedItem: IRowWallet) => {
        setWalletToDelete(selectedItem);
        setOpenDeleteConfirmationState(true);
    }

    const getEnableBankAgreement = () => {

        if (!properties.currentPaymentConditionId)
            return null;

        const currentPaymentConditionId = properties.currentPaymentConditionId;
        const currentPaymentCondition = properties.productConfig.paymentConditions?.find(x => x.id === currentPaymentConditionId);
        const enableBankAgreement = currentPaymentCondition.bankAgreements?.find(x => x.enable);

        return enableBankAgreement;
    }

    const updateProductConfig = (wallets: Array<WalletDto>) => {
        const enableBankAgreement = getEnableBankAgreement();

        if (!enableBankAgreement)
            return;

        enableBankAgreement.wallets = wallets;
    }

    const handleDeleteWallet = async (walletToDelete: IRowWallet) => {

        setShowWalletFieldsState(false);
        setOpenDeleteConfirmationState(false);

        const newWallets = tableDataState.rows.filter(item => (item.id !== walletToDelete.id))
            .map((wallet, index) => new WalletDto((index + 1).toString(), wallet));

        const newTableDataState = new WalletTableStructure(newWallets, newWallets.length);

        currentWalletsVariable = newWallets;

        updateProductConfig(newWallets);

        setTableDataState(newTableDataState);
    }

    const handleWalletFields = () => {
        setShowWalletFieldsState(true);
        setShowAddNewWalletButtonState(false);
    }

    const createBankAgreement = (paymentConditionConfig: PaymentConditionConfigDto) => {
        const bankAgreement = new BoletoBankAgreement();

        bankAgreement.enable = true;
        bankAgreement.bankName = paymentConditionConfig.bankName;
        bankAgreement.bankNumber = paymentConditionConfig.bankNumber;
        bankAgreement.companyName = paymentConditionConfig.companyName;
        bankAgreement.providerName = paymentConditionConfig.providerName;
        bankAgreement.companyDocument = paymentConditionConfig.companyDocument;
        bankAgreement.wallets = currentWalletsVariable;
        return bankAgreement;
    }

    return (
        <>
            <Grid item md={3} className={classes.grid}>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Nome do banco
                </InputLabel>
                <NativeSelect
                    {...properties.registerForm(fieldNames.BANK_NAME)}
                    className={classes.selector}
                    defaultValue={fieldDefaultValues.BANK_NAME}
                >
                    {getEnumDescriptions(BankNameLabel, BankEnum as unknown as EnumType).map(item => {
                        return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                </NativeSelect>

            </Grid>

            <Grid item md={3} className={classes.grid}>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Número do banco
                </InputLabel>
                <NativeSelect
                    {...properties.registerForm(fieldNames.BANK_NUMBER)}
                    className={classes.selector}
                    defaultValue={fieldDefaultValues.BANK_NUMBER}
                >
                    {getEnumDescriptions(BankNumberLabel, BankEnum as unknown as EnumType).map(item => {
                        return (<option key={item.value} value={item.text}>{item.text}</option>);
                    })}
                </NativeSelect>
            </Grid>

            <Grid item md={3} className={classes.grid}>
                <TextField
                    {...properties.registerForm(fieldNames.PROVIDER_NAME, {
                        required: true,
                    })}
                    className={classes.inputValue}
                    id="standard-basic"
                    label="Nome do Provedor"
                    defaultValue={fieldDefaultValues.PROVIDER_NAME}
                    variant="standard"
                />
                {errors?.providerName?.type === "required" && <p className={classes.validationMessage}>Informe o nome do provedor</p>}
            </Grid>

            <Grid item md={3} className={classes.grid}>
                <TextField
                    {...properties.registerForm(fieldNames.COMPANY_NUMBER, {
                        required: true,
                    })}
                    className={classes.inputValue}
                    inputProps={{ maxLength: 14 }}
                    id="standard-basic"
                    label="CNPJ"
                    defaultValue={fieldDefaultValues.COMPANY_NUMBER}
                    variant="standard"
                    type="number" />
                {errors?.cnpj?.type === "required" && <p className={classes.validationMessage}>Informe o CNPJ</p>}
            </Grid>

            <Grid item md={3} className={classes.grid}>
                <TextField
                    {...properties.registerForm(fieldNames.COMPANY_NAME, {
                        required: true,
                    })}
                    className={classes.inputValue}
                    id="standard-basic"
                    label="Nome da Compania"
                    variant="standard"
                    defaultValue={fieldDefaultValues.COMPANY_NAME}
                />
                {errors?.companyName?.type === "required" && <p className={classes.validationMessage}>Informe o nome da compania</p>}
            </Grid>

            {showAddNewWalletButtonState && (
                <>
                    <Grid container justifyContent="space-between" className={classes.inputValuesHeader}>
                        <Typography variant="subtitle1" component="h2" />
                        <Button
                            className={classes.inputValuesCommand}
                            color="primary"
                            title="Adicionar carteira"
                            variant="outlined"
                            onClick={handleWalletFields}
                            startIcon={<Add />}
                        >
                            Adicionar nova carteira
                        </Button>
                    </Grid>
                </>
            )}

            {showWalletFieldsState && (
                <>
                    <Grid item md={3}
                        className={classes.grid}>
                        <TextField
                            {...register(fieldNames.LIMIT_DAYS_AFTER_EXPIRATION, {
                                required: true,
                                min: 0
                            })}
                            className={classes.inputValue}
                            inputProps={{ maxLength: 3 }}
                            id="standard-basic"
                            label="Limite de dias após a expiração"
                            variant="standard"
                            type="number" />
                        {errors?.limitDaysAfterExpiration?.type === "required" && <p className={classes.validationMessage}>Informe o limite de dias após a expiração</p>}
                        {errors?.limitDaysAfterExpiration?.type === "min" && <p className={classes.validationMessage}>Limite deve ser maior ou igual a zero</p>}
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <TextField
                            {...register(fieldNames.AGENCY_NUMBER, {
                                required: true,
                            })}
                            className={classes.inputValue}
                            id="standard-basic"
                            label="Número da agência"
                            variant="standard"
                            defaultValue={fieldDefaultValues.AGENCY_NUMBER}
                            type="number" />
                        {errors?.agencyNumber?.type === "required" && <p className={classes.validationMessage}>Informe o número da agência</p>}
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <TextField
                            {...register(fieldNames.ACCOUNT_NUMBER, {
                                required: true,
                            })}
                            className={classes.inputValue}
                            id="standard-basic"
                            label="Número da conta"
                            variant="standard"
                            defaultValue={fieldDefaultValues.ACCOUNT_NUMBER}
                            type="number" />
                        {errors?.accountNumber?.type === "required" && <p className={classes.validationMessage}>Informe o número da conta</p>}
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <TextField
                            {...register(fieldNames.ACCOUNT_NUMBER_DIGIT, {
                                required: true,
                            })}
                            className={classes.inputValue}
                            inputProps={{ maxLength: 2 }}
                            id="standard-basic"
                            label="Dígito da conta"
                            variant="standard"
                            defaultValue={fieldDefaultValues.ACCOUNT_NUMBER_DIGIT}
                            type="number"
                        />
                        {errors?.accountNumberDigit?.type === "required" && <p className={classes.validationMessage}>Informe o dígito da conta</p>}
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <TextField
                            {...register(fieldNames.WALLET_CODE, {
                                required: true,
                            })}
                            className={classes.inputValue}
                            id="standard-basic"
                            label="Código da carteira"
                            variant="standard"
                            defaultValue={fieldDefaultValues.WALLET_CODE}
                        />
                        {errors?.walletCode?.type === "required" && <p className={classes.validationMessage}>Informe o código da carteira</p>}
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <TextField
                            {...register(fieldNames.WALLET_NUMBER, {
                                required: true,
                            })}
                            className={classes.inputValue}
                            id="standard-basic"
                            label="Número da carteira"
                            variant="standard"
                            defaultValue={fieldDefaultValues.WALLET_NUMBER}
                            type="number"
                        />
                        {errors?.walletNumber?.type === "required" && <p className={classes.validationMessage}>Informe o número da carteira</p>}
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <TextField
                            {...register(fieldNames.AGREEMENT_NUMBER, {
                                required: true,
                            })}
                            className={classes.inputValue}
                            id="standard-basic"
                            label="Número do contrato"
                            variant="standard"
                            defaultValue={fieldDefaultValues.AGREEMENT_NUMBER}
                            type="number"
                        />
                        {errors?.agreementNumber?.type === "required" && <p className={classes.validationMessage}>Informe o número do contrato</p>}
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Tipo de documento
                        </InputLabel>
                        <NativeSelect
                            {...register(fieldNames.DOCUMENT_TYPE)}
                            className={classes.selector}
                            defaultValue={fieldDefaultValues.DOCUMENT_TYPE}
                        >
                            {documentTypes.map(item => {
                                return (<option key={item.value} value={item.value}>{item.text}</option>);
                            })}
                        </NativeSelect>
                    </Grid>

                    <Grid item md={3} className={classes.grid}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Tipo processamento de pagamento
                        </InputLabel>
                        <NativeSelect
                            {...register(fieldNames.PAYMENT_PROCESSING_TIME_TYPE)}
                            className={classes.selector}
                            defaultValue={fieldDefaultValues.PAYMENT_PROCESSING_TIME_TYPE}
                        >
                            {paymentProcessingTimeTypes.map(item => {
                                return (<option key={item.value} value={item.value}>{item.text}</option>);
                            })}
                        </NativeSelect>
                    </Grid>

                    <Grid container justifyContent="space-between" className={classes.inputValuesHeader}>
                        <Typography variant="subtitle1" component="h2">
                            <Box fontWeight="bold">Configurações de Carteiras</Box>
                            <Divider className={classes.divider} />
                        </Typography>
                        <Button
                            className={classes.inputValuesCommand}
                            color="primary"
                            title="Gravar carteira"
                            variant="outlined"
                            onClick={handleSubmit(addNewWallet)}
                            startIcon={<AddCircle />}
                        >
                            Gravar Carteiras
                        </Button>
                    </Grid>
                </>
            )}
            <MessageBox
                opened={walletDuplicatedMessageOpenedState}
                handleClose={() => {
                    setWalletDuplicatedMessageOpenedState(false)
                }}
                handleSecondary={() => {
                    setWalletDuplicatedMessageOpenedState(false)
                }}
                labelSecondary="Fechar"
                type='warning'
                title="Não foi possível incluir a carteira"
                text="Carteira já cadastrada !"
            />
            <MessageBox
                opened={openDeleteConfirmationState}
                handleClose={() => setOpenDeleteConfirmationState(false)}
                handlePrimary={() => handleDeleteWallet(walletToDelete)}
                handleSecondary={() => setOpenDeleteConfirmationState(false)}
                type='warning'
                title="Excluir a wallet selecionada!"
                text="Deseja Excluir? Ao clicar em Confirmar todas as informações desta configuração serão perdidas!"
            />

            <Grid item md={12} className={classes.grid}>
                <DynamicTable
                    data={tableDataState}
                    actionEditClick={(props) => actionSelectWallet(props)}
                    actionDeleteClick={(props) => actionDelete(props)}
                    name={"Boleto Fields"}
                />
            </Grid>
        </>
    )
}
