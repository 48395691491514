import { ModalityTypeNumber } from "@enums/modality-type.enum";
import IofConfig from "@models/product-config/iof-config.model";
import { InsuranceTypeNumber } from "@enums/insurance-type.enum";
import { InsuranceTypeEnum, ModalityType, RefundIofLabel } from 'enums';
import { IRowTableIofConfig } from "@components/product-config/iof-config/interfaces/table.row.interface";

export default class IofConfigDto implements IRowTableIofConfig {
    id: string;

    iofPercentage: number;

    modalityType: ModalityType;

    insuranceType: InsuranceTypeEnum;

    refundIofDescription: string;

    refundIof: boolean;

    constructor(id: string, iofConfig: IofConfig) {
        this.id = id;
        this.iofPercentage = iofConfig?.iofPercentage;
        this.refundIof = iofConfig?.refundIof ?? false;
        this.refundIofDescription = RefundIofLabel.get(Number(this.refundIof));
        this.modalityType = iofConfig ? ModalityTypeNumber.get(iofConfig.modalityType.toString()) : null;
        this.insuranceType =  InsuranceTypeNumber.get(iofConfig.insuranceType)
    }
}