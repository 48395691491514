import { IData } from "@components/dynamic-table/intefaces/data.interface";
import PaymentConditionConfigDto from "@components/product-config/product-payment-condition/dtos/payment-condition-config.dto";
import { IRowPaymentConditionConfig } from "@components/product-config/product-payment-condition/interfaces/table.row.interface";
import { IColumnTablePaymentConditionConfig } from "@components/product-config/product-payment-condition/interfaces/table.column.interface";
import { InsuranceTypeLabel, IsDefaultPaymentTypeLabel, PaymentTypeEnumLabel } from "enums";
import {EnableOnPortalTypeLabel} from "@enums/enable-on-portal.enum";

export class PaymentConditionTableStructure implements IData {

    columns: Array<IColumnTablePaymentConditionConfig> = [];

    rows: Array<IRowPaymentConditionConfig> = [];

    totalRecords: number;

    constructor(paymentConditionsConfigDto: Array<PaymentConditionConfigDto>, totalRecords: number) {
        this.totalRecords = totalRecords;
        this.columns = [
            { name: 'id', visible: false },
            { name: 'Forma de pagamento', visible: true },
            { name: 'PaymentType', visible: false },
            { name: 'Limite de Parcelas', visible: true },
            { name: 'Tipo de Seguro', visible: true },
            { name: 'InsuranceType', visible: false },
            { name: 'Principal', visible: true },
            { name: 'Habilitado nos Portais', visible: true },
        ];

        this.rows = new Array<IRowPaymentConditionConfig>();

        paymentConditionsConfigDto.forEach(paymentConditionConfig => {
            const row = {
                id: paymentConditionConfig.id,
                paymentTypeDescription: PaymentTypeEnumLabel.get(paymentConditionConfig.paymentType),
                paymentType: paymentConditionConfig.paymentType,
                maxInstallments: paymentConditionConfig.maxInstallments,
                insuranceTypeDescription: InsuranceTypeLabel.get(paymentConditionConfig.insuranceType),
                insuranceType: paymentConditionConfig.insuranceType,
                isDefaultPaymentTypeDescription: IsDefaultPaymentTypeLabel.get(Number(paymentConditionConfig.isDefaultPaymentType)),
                enableOnPortalDescription: EnableOnPortalTypeLabel.get(Number(paymentConditionConfig.enableOnPortal)),
                isDefaultPaymentType: paymentConditionConfig.isDefaultPaymentType,
                enableOnPortal: paymentConditionConfig.enableOnPortal,
                delayPercentage: paymentConditionConfig.delayPercentage,
                interestPercentage: paymentConditionConfig.interestPercentage,

                // todo mudar para herança BOLETO BANK AGREEMENT
                enable: true,
                companyDocument: paymentConditionConfig.companyDocument,
                bankName: paymentConditionConfig.bankName,
                bankNumber: paymentConditionConfig.bankNumber,
                companyName: paymentConditionConfig.companyName,
                providerName: paymentConditionConfig.providerName,
                limitDaysAfterExpiration: paymentConditionConfig.limitDaysAfterExpiration,
                wallets: paymentConditionConfig.wallets,

                // todo mudar para herança CREDIT CARD
                urlExpirationMinutes: paymentConditionConfig.urlExpirationMinutes,
                paymentExpirationMinutes: paymentConditionConfig.paymentExpirationMinutes

            } as IRowPaymentConditionConfig;
 
            this.rows.push(row);
        })
    }
}
